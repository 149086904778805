import {Fragment} from 'react';
import {css, cx} from '@emotion/css';

import {Markdown} from '../../../../components/Markdown.jsx';

import {material} from '../../../../utilities/colors';

const styles = {
  parameters: css({
    margin: '16px 0 16px 16px',
  }),
  list: css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    padding: '4px 8px',
    backgroundColor: material.grey[100],
    borderRadius: '3px',
  }),
  name: css({
    // padding: '0 8px 0 0',
    paddingRight: '16px',
    color: material.grey[600],
  }),
  value: css({
    color: material.grey[800],
  }),
  parameter: css({
    paddingTop: '4px',
    paddingBottom: '4px',
    borderBottom: `1px solid ${material.grey[300]}`,
    fontFamily: 'var(--bs-font-monospace)',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    '&:last-child, &:nth-last-child(-n + 2)': {
      paddingBottom: '0',
      borderBottom: 'none',
    }
  }),
};

function Parameters(props) {
  return (
    <div className={cx(styles.parameters)}>
      {props.description &&
        <Markdown>
          {props.description}
        </Markdown>
      }
      <div className={cx(styles.list)}>
        {props.pairs.map((parameter, index) => {
          return (
            <Fragment key={index}>
              <div className={cx(styles.parameter, styles.name)}>
                {parameter.name}
              </div>
              <div className={cx(styles.parameter, styles.value)}>
                {parameter.value}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export {Parameters};