import {useReducer, useEffect, useCallback, useState, Fragment} from 'react';
import {Buffer} from 'buffer';

import * as bytes from '../../packages/utilities/bytes.js';
import {SorobanProvider, useSoroban} from '../../packages/soroban/Soroban.jsx';
import Stellar from '../../packages/soroban/xdr/stellar.js';
import * as XDR from 'js-xdr';
import * as base64 from '../../packages/utilities/base64.js';
import * as hex from '../../packages/utilities/hex.js';
import {Keys} from '../../packages/stellar/Keys.js';

import {Network} from '../../packages/soroban/Network/Network.js';
import {Account} from '../../packages/stellar/Account.js';
import {Transaction} from '../../packages/stellar/Transaction/Transaction.js';
import {Precondition} from '../../packages/stellar/Transaction/Precondition.js';
import * as Operation from '../../packages/stellar/Transaction/Operation';
import {Code} from '../../packages/soroban/Code/Code.js';
import {Result} from '../../packages/stellar/Transaction/Result/Result.js';

function reducer(state, action) {
  switch(action.type) {
    case 'updateSecretKey': {
      return {
        ...state,
        secretKey: action.payload
      };
    }
    case 'storeContractCode': {
      return {
        ...state,
        contractCode: action.payload
      };
    }
    case 'addTransaction': {
      return {
        ...state,
        transactions: [
          ...state.transactions,
          {
            id: action.payload.id,
            status: action.payload.status
          }
        ]
      };
    }
    case 'updateTransactions': {
      return {
        ...state,
        transactions: action.payload
      };
    }
    case 'startObservingTransactions': {
      return {
        ...state,
        transactionObserver: action.payload
      };
    }
    case 'stopObservingTransactions': {
      return {
        ...state,
        transactionObserver: null
      };
    }
    default: {
      return state;
    }
  }
}

// GDNBE2HBUKMUO5B4X7MFJWIZM36DSSFI6B4E4K2KMIBBAPXDUKAWWMGK
// SDK5PJSBMYJPU3HYCUYUOBX2K73DXXICUU32Z536LZCH7URR5HSAQGUD
function ContractTool(props) {
  const soroban = useSoroban();
  const [state, dispatch] = useReducer(reducer, {
    account: null,
    secretKey: 'SAEWT32IC2A77HNKNI6FJA2YQEJN24SDH6U3QVLFW2QEPQ5JZMENNCCD',
    contractCode: new Uint8Array([
      0, 97, 115, 109, 1, 0, 0, 0, 1, 18, 4, 96, 2, 126, 126, 1, 126, 96, 0, 1, 126, 96, 1, 126, 0, 96, 0, 0, 2, 7, 1, 1, 108, 1, 95, 0, 0, 3, 5, 4, 1, 2, 1, 3, 5, 3, 1, 0, 16, 6, 25, 3, 127, 1, 65, 128, 128, 192, 0, 11, 127, 0, 65, 128, 128, 192, 0, 11, 127, 0, 65, 128, 128, 192, 0, 11, 7, 52, 6, 6, 109, 101, 109, 111, 114, 121, 2, 0, 2, 111, 110, 0, 1, 3, 111, 102, 102, 0, 3, 1, 95, 0, 4, 10, 95, 95, 100, 97, 116, 97, 95, 101, 110, 100, 3, 1, 11, 95, 95, 104, 101, 97, 112, 95, 98, 97, 115, 101, 3, 2, 10, 49, 4, 12, 0, 66, 21, 16, 130, 128, 128, 128, 0, 66, 5, 11, 18, 0, 66, 137, 186, 201, 164, 218, 15, 32, 0, 16, 128, 128, 128, 128, 0, 26, 11, 12, 0, 66, 37, 16, 130, 128, 128, 128, 0, 66, 5, 11, 2, 0, 11, 0, 30, 17, 99, 111, 110, 116, 114, 97, 99, 116, 101, 110, 118, 109, 101, 116, 97, 118, 48, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 26, 0, 55, 14, 99, 111, 110, 116, 114, 97, 99, 116, 115, 112, 101, 99, 118, 48, 0, 0, 0, 0, 0, 0, 0, 2, 111, 110, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 111, 102, 102, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ]),
    transactions: [
      // {
      //   id: '3c51d99753e6adb19c96b0f4c34e23ca9313ede6313c8add5b6a51e7b9e12e89',
      //   status: 'pending'
      // },
      // {
      //   id: '2768a612e0c1da54ebe324ba061ab5f59767ba6b74b81e35a0484f487022ca96',
      //   status: 'pending'
      // }
    ],
    transactionObserver: null
  });

  let account = null;
  try {
    account = account = Account.fromKey(state.secretKey);
  } catch (error) {

  }
  // console.log(XDR);
  // console.log(Stellar);
  // console.log(state);
  // console.log(state);

  const checkTransactions = useCallback(
    async () => {
      let nextTransactions = [];
      let shouldDispatch = false;
      for (const transaction of state.transactions) {
        if (transaction.status === 'pending') {
          shouldDispatch = true;
          const response = await soroban.network.getTransactionStatus(transaction.id);
          nextTransactions.push({
            ...transaction,
            status: response.status,
            response
          });
          if (response.status === 'error') {
            console.log(transaction.id, response.error);
          }
        } else {
          nextTransactions.push(transaction);
        }
      }
  
      if (shouldDispatch) {
        dispatch({
          type: 'updateTransactions',
          payload: nextTransactions
        });
      }
    },
    [state.transactions, soroban.network]
  );

  useEffect(() => {
    const pendingTransactions = state.transactions.filter(
      (candidate) => candidate.status === 'pending'
    );

    if (pendingTransactions.length > 0 && state.transactionObserver === null) {
      const observer = setInterval(checkTransactions, 1000);
      dispatch({
        type: 'startObservingTransactions',
        payload: observer
      });
    } else if (pendingTransactions.length === 0 && state.transactionObserver !== null) {
      clearInterval(state.transactionObserver);
      dispatch({type: 'stopObservingTransactions'});
    }
  }, [state.transactions, state.transactionObserver, checkTransactions]);

  const test = async () => {
    const code = new Code(state.contractCode);
    const key = Stellar.LedgerKey.contractCode(new Stellar.LedgerKeyContractCode({
      hash: await code.hash()
    }));
    const keyEncoded = base64.encode(key.toXDR());
    const response = await soroban.network.getLedgerEntry(keyEncoded);
    console.log(response);
  };

  const onSecretKeyChange = (event) => {
    dispatch({
      type: 'updateSecretKey',
      payload: event.target.value
    })
  };
  const onCompiledContractChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const contractCode = new Uint8Array(event.target.result);
      dispatch({
        type: 'storeContractCode',
        payload: contractCode
      });
    };
    fileReader.readAsArrayBuffer(file);
  };

  const installContract = async () => {
    const network = Network.local;
    const code = new Code(state.contractCode);
    const transaction = await new Transaction()
      .sourceAccount(account)
      .operation(
        new Operation.InstallContractCode()
          .code(code)
      )
      .serializedFor(soroban.network);
      
    await transaction.signedBy(account);
    
    const response = await soroban.network.sendTransaction(transaction.finalized());
    console.log(response);
    
    dispatch({
      type: 'addTransaction',
      payload: response
    });
  }

  const createContract = async () => {
    const network = Network.local;
    const code = new Code(state.contractCode);
    const transaction = await new Transaction()
      .sourceAccount(account)
      .operation(
        new Operation.CreateContract()
          .code(code)
          .network(soroban.network)
          .sourceAccount(account)
      )
      .serializedFor(soroban.network);

    await transaction.signedBy(account);
    
    const response = await soroban.network.sendTransaction(transaction.finalized());
    
    dispatch({
      type: 'addTransaction',
      payload: response
    });
  };

  const pay = async (amount) => {
    const network = Network.local;
    const transaction = await new Transaction()
      .sourceAccount(account)
      .fee(100)
      .preconditions(Precondition.TimeBounds(0, Math.floor(Date.now() / 1000 + 60)))
      .operation(
        new Operation.Payment()
          .recipient(Account.fromKey('GB245HBHTR7BH34HXT2H3PH2UDJOAKDOAOC2WQWF37PBYLBYMDIWVWQ3'))
          .amount(1.52)
      )
      .serializedFor(soroban.network);
      
    await transaction.signedBy(account);
    const response = await soroban.network.sendTransaction(transaction.finalized());
    
    dispatch({
      type: 'addTransaction',
      payload: response
    });
  };

  const [status, setStatus] = useState(null);

  const friendbot = async () => {
    setStatus('funding...');
    const response = await fetch(`http://localhost:8000/friendbot?addr=${account.address}`);
    const responseJson = await response.json();
    setStatus('funded!');
    console.log(responseJson);
  };

  return (
    <div className="container-fluid">
      <div className="row g-2">
        <div className="col-12 mb-3">
          <div className="form-floating mb-1">
            <input
              type="text"
              id="contract.account"
              className="form-control"
              placeholder="Secret key"
              value={state.secretKey || ''}
              onChange={onSecretKeyChange}
            />
            <label htmlFor="contract.account">
              Secret key
            </label>
          </div>
          <div className="form-floating">
            <input
              type="text"
              id="contract.account"
              className="form-control"
              placeholder="Public key"
              value={account ? account.keys.formattedPublicKey : ''}
              disabled={true}
              onChange={() => {}}
            />
            <label htmlFor="contract.account">
              Public key
            </label>
          </div>
          <div id="contract.account" className="form-text">
            The source account for the transaction to install a contract.
          </div>
        </div>

        <div className="col-12 mb-5">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={friendbot}
            disabled={status !== null}
          >
            {status ? status : 'friendbot'}
          </button>
        </div>

        <div className="col-12 mb-1">
          <label htmlFor="contract.compiledContract" className="form-label">
            Compiled contract
          </label>
          <input
            type="file"
            id="contract.compiledContract"
            className="form-control"
            onChange={onCompiledContractChange}
          />
          <div id="contract.compiledContract.help" className="form-text">
            Upload the <code>*.wasm</code> file, located in your project's <code>target/wasm32-unknown-unknown/release/</code> folder.
          </div>
        </div>

        {state.contractCode &&
          <div className="col-12 mb-5">
            <span className="badge bg-secondary me-2">Size</span>
            <code>{state.contractCode.length}B</code>
          </div>
        }

        <div className="col-12">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={installContract}
          >
            install
          </button>
        </div>
        <div className="col-12 mb-5">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={createContract}
          >
            create
          </button>
        </div>
        {/* <div className="col-12 mb-5">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={pay}
          >
            pay
          </button>
        </div> */}

        <table className="table table-hover table-sm col-12">
          <thead>
            <tr>
              <th scope="col">Transaction ID</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {state.transactions.map((transaction, index) => {
              return (
                <Fragment key={`${transaction.id}+${index}`}>
                  <tr>
                    <td>
                      <code>{transaction.id}</code>
                    </td>
                    <td>
                      <code>{transaction.status}</code>
                    </td>
                  </tr>
                  {transaction.response && transaction.response.error && transaction.response.error.data &&
                    <tr>
                      <td colSpan="2">
                        <pre>
                          <code>
                            {JSON.stringify(
                              Result.fromXDR(
                                transaction.response.error.data.transaction.result_xdr
                              ),
                              null,
                              2
                            )}
                          </code>
                        </pre>
                      </td>
                    </tr>
                  }
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function WrappedContractTool(props) {
  return (
    <SorobanProvider
      networkPassphrase="Test SDF Future Network ; October 2022"
      remoteUrl="https://raph-futurenet.stellar.quest/soroban/rpc"
    >
      <ContractTool />
    </SorobanProvider>
  );
}

export {WrappedContractTool as ContractTool};