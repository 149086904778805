import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';

import {Code} from '../../../../components/Code.jsx';

function Request(props) {
  const soroban = useSoroban();

  let invokeCommand = null;
  // console.log(props.parameters);

  const containsInvoke = !!props.parameters?.find((parameter) => (
    parameter.props.name === 'hostFunction' &&
    parameter.props.value === 'InvokeContract'
  ));

  if (containsInvoke) {
    invokeCommand = 'soroban invoke';
    const urlParts = soroban.network.remoteUrl.split('/');
    urlParts[2] = `${urlParts[2]}:${urlParts[0] === 'https:' ? 443 : 80}`
    const remoteUrl = urlParts.join('/');

    invokeCommand += (
      ` \\\n  --rpc-url ${remoteUrl}` +
      ` \\\n  --network-passphrase '${soroban.network.passphrase}'`
    );

    const secretKey = props.parameters.find((parameter) => parameter.props.name === 'sourceAccount');
    if (secretKey?.props.value) {
      invokeCommand += ` \\\n  --secret-key ${secretKey.props.value}`;
    }

    const contractId = props.parameters.find((parameter) => parameter.props.name === 'contractId');
    if (contractId?.props.value) {
      invokeCommand += ` \\\n  --id ${contractId.props.value}`;
    }

    const functionName = props.parameters.find((parameter) => parameter.props.name === 'functionName');
    if (functionName?.props.value) {
      invokeCommand += ` \\\n  --fn ${functionName.props.value}`;
    }

    const functionArguments = props.parameters.find((parameter) => parameter.props.name === 'functionArguments');
    if (functionArguments?.props.value) {
      try {
        for (const argument of functionArguments.props.value) {
          if (argument.xdr !== undefined && argument.xdr !== null) {
            invokeCommand += ` \\\n  --arg-xdr ${argument.xdr}`;
          }
        }
      } catch (error) {

      }
      // console.log(functionArguments.props.value);
    }
  }

  let curl = `curl ${props.url}`;

  curl += (
    ` \\\n` +
    `-H 'Content-Type: application/json' \\\n` +
    `-X POST`
  );

  const payload = {
    jsonrpc: '2.0',
    id: `${Date.now()}`,
    method: props.body.method
  };
  if (props.body.parameters) {
    payload.params = props.body.parameters;
  }

  const formattedPayload = JSON.stringify(payload, null, 2)
    .split('\n')
    .slice(1)
    .slice(0, -1)
    .map((line) => '  ' + line)
    .join('\n')

  curl += (
    ` \\\n` +
    `--data-raw '{\n` +
    `${formattedPayload}\n` +
    `}'`
  );

  return (
    <>
      {invokeCommand &&
        <Code language="language-sh" source={invokeCommand} />
      }
      <Code
        language="language-sh"
        source={curl}
      />
    </>
  );
}

export {Request};