import {cx} from '@emotion/css';

import {Wrapper} from './components/Wrapper.jsx';

function Text(props) {
  const hasFloatingLabel = props.hasFloatingLabel !== undefined ? props.hasFloatingLabel : true;
  return (
    <Wrapper
      hasFloatingLabel={hasFloatingLabel}
      isGrouped={!!props.isGrouped}
      label={hasFloatingLabel ? props.placeholder : props.label}
    >
      <input
        type={props.type}
        className={cx('form-control', props.className)}
        placeholder={props.placeholder}
        value={props.value !== undefined && props.value !== null ? props.value : ''}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </Wrapper>
  );
}

export {Text};