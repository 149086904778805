// import {NavLink as Link} from 'react-router-dom';

import {Guide} from './components/Guide.jsx';
import {Markdown} from '../../components/Markdown.jsx';
import {Annotation} from '../../components/Annotation.jsx';

const content = `
### Stellar Network

Learning a new language, seeing your creations built and running, those are all very exciting for a developer. Above all that is the excitement of sharing your work with others to see and use. When you're building web apps, that means hosting your app on a server for others to access using web browsers. When it's a mobile app, well, then you go through the app store submission process to ultimately enable users to download it to their devices.

For smart contracts that ultimate destination is a public blockchain. Soroban, while it's in development, can be deployed only on the Stellar Futurenet or a locally emulated version of the Stellar Futurenet.

Interacting with both versions of the network, the local and the public, is the same, other than a simple \`docker\` flag. It's easier to start with a local version, so that's where we'll start.

Before you run this command, make sure you have *Docker* installed, here's a [guide][1] if you don't.

~~~sh
docker run \\
  --rm \\
  -it \\
  --platform linux/amd64 \\
  -p 8000:8000 \\
  --name stellar \\
  stellar/quickstart:soroban-dev@sha256:8968d5c3344fe447941ebef12c5bede6f15ba29b63317a488c16c5d5842e4a71 \\
  --standalone \\
  --enable-soroban-rpc
~~~

After executing this command, you should see the following in your terminal.

~~~
Starting Stellar Quickstart

mode: ephemeral
network: standalone (Standalone Network ; February 2017)
...
2022-12-05 10:08:54,146 INFO success: postgresql entered RUNNING state, process has stayed up for > than 1 seconds (startsecs)
2022-12-05 10:08:54,146 INFO success: stellar-core entered RUNNING state, process has stayed up for > than 1 seconds (startsecs)
2022-12-05 10:08:54,146 INFO success: horizon entered RUNNING state, process has stayed up for > than 1 seconds (startsecs)
2022-12-05 10:08:54,147 INFO success: friendbot entered RUNNING state, process has stayed up for > than 1 seconds (startsecs)
2022-12-05 10:08:54,147 INFO success: nginx entered RUNNING state, process has stayed up for > than 1 seconds (startsecs)
2022-12-05 10:08:54,147 INFO success: soroban-rpc entered RUNNING state, process has stayed up for > than 1 seconds (startsecs)
~~~

As you can see, we have spun up a number of processes to be able to host our own instance of Stellar Futurenet.

1. \`postgresql\` is the database used by Horizon for quick access to blockchain data.
2. [\`stellar-core\`][2] runs the actual Stellar blockchain node.
3. [\`horizon\`][3] is the HTTP API for Stellar.
4. [\`friendbot\`][4] is the utility for creating and funding new accounts in test environments.
5. \`nginx\` is a web server used by horizon and soroban-rpc.
6. [\`soroban-rpc\`][5] Soroban related utility

#### Create an Account

Now that we have our own local instance of Stellar running, let's start interacting with it.

To be able to read and write to Stellar, we need an account. [Accounts][6] are the central data structure in Stellar. To create one, we need a set keys (cryptographic keypair containing one public and one private key) and we need to fund the account with a minimum balance. We're working on a local network, so feel free to use the keys below. I'll be using them in later code examples so that you can follow along by simply copying from the code blocks.

However, if you want to generate your own keypair, you can do so using this Stellar Laboratory [utility][7].

~~~
Public key: GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW
Secret key: SAEWT32IC2A77HNKNI6FJA2YQEJN24SDH6U3QVLFW2QEPQ5JZMENNCCD
~~~

To make it easier to distinguish, Stellar public keys always start with the capital letter \`G\`, and secret keys always start with the capital letter \`S\`.

OK, so we have satisfied the first requirement to create an account - having a keypair. Now, with the help of the \`friendbot\` utility, we will create and fund this account with some \`XLM\` tokens, or Lumens. Friendbot is made accessible through the same Horizon HTTP API. Try executing this command, with the \`addr\` query param set to your *public key*.

~~~sh
curl "http://localhost:8000/friendbot?addr=GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW"
~~~

You'll see what looks like JSON as the result in your terminal.

~~~
{
  "_links": {
    ...
  },
  "id": "1e2f194413a9b9197acf4c358719bca5292d803c3dee39eacd8515fddba3d9de",
  "created_at": "2022-12-05T12:14:54Z",
  "source_account": "GDVGCV62A6JVAECDRUUEFQ23R43AHU3P7MJD65CWARRZYOP33YN56XLT",
  "envelope_xdr": "AAAAAgAAAADqYVfaB5NQEEONKELDW482A9Nv+xI/dFYEY5w5+94b3wABhqAAAAAEAAAAAQAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAABAAAAAHN2/eiOTNYcwPspSheGs/HQYfXy8cpXRl+qkyIRuUbWAAAAAAAAAAAWlh0IJ9cDjZFWFhen9mgg/dd2TQm+p56ntMZ2LPuVdAAAABdIdugAAAAAAAAAAAL73hvfAAAAQHnNxrwm0zyNFSmFEd66bJIV9BrlrL83SR2SE5oOafJG+BB8mnBvLmaJunJRVEVRxPmddRM7bl75SLgVni+9XAkRuUbWAAAAQMKDGmQMRHrYtYSnK33uKvFjbo3kbNNezUvQV3mTqEmunLg/GFRHybPIcA+Kfxk4IdMzx4CG9aR6kSF/eoi/Igs=",
  ...
}
~~~

In fact, we are looking at a Horizon API response to a transaction request by the Friendbot. It has a \`_links\` section with various URLs to relevant resources. You'll find the transaction \`id\` as well as the time it was created and the \`source_account\`.

If you want to parse the contents of this transaction, you can take the value of \`envelope_xdr\` and decode it using the Stellar laboratory [View XDR][8] tool. In this case, you'll see an array of \`operations\` with a single operation - \`createAccountOp\` and the destination set to our public key \`GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW\`.

We can now confirm this account has been *created* and *funded* by querying Horizon.

~~~sh
curl http://localhost:8000/accounts/GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW
~~~

We should get a JSON result with the account \`id\` equal to our public key, and a balances array with \`10,000\` XLM tokens, with the \`asset_type\` set to \`native\`.

~~~
{
  ...
  "id": "GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW",
  ...
  "balances": [
    {
      "balance": "10000.0000000",
      "buying_liabilities": "0.0000000",
      "selling_liabilities": "0.0000000",
      "asset_type": "native"
    }
  ],
  ...
}
~~~

#### Deploy a Soroban Contract

Now that we have an account, we can deploy our Soroban contract to our local instance of Stellar. To refresh your memory on how to build a Soroban contract, please refer to the [Soroban CLI][9] guide.

~~~sh
soroban deploy \\
  --wasm target/wasm32-unknown-unknown/release/project.wasm \\
  --secret-key SAEWT32IC2A77HNKNI6FJA2YQEJN24SDH6U3QVLFW2QEPQ5JZMENNCCD \\
  --rpc-url http://localhost:8000/soroban/rpc \\
  --network-passphrase 'Standalone Network ; February 2017'
~~~

If all goes well, you'll get a result with that Soroban contract \`ID\`.

~~~
success
29280a72da31b69334b15ef78a5aa7340f56b14ccda05423df3f6cb5ec60abb7
~~~

#### Interact with a Soroban Contract

Our contract is now live on the blockchain (even if it's just locally)! Let's celebrate by invoking the \`add\` function one time.

~~~sh
soroban invoke \\
  --rpc-url http://localhost:8000/soroban/rpc \\
  --network-passphrase 'Standalone Network ; February 2017' \\
  --secret-key SAEWT32IC2A77HNKNI6FJA2YQEJN24SDH6U3QVLFW2QEPQ5JZMENNCCD \\
  --id 29280a72da31b69334b15ef78a5aa7340f56b14ccda05423df3f6cb5ec60abb7 \\
  --fn add \\
  --arg 1 \\
  --arg 2
~~~

Since \`1 + 2 = 3\`, we should get \`3\` in the result.

~~~
success
3
~~~

Cool! Now, if we looked up the account balance again, we'd see it's now \`9,999.99999\` because invoking the contract function \`add\` requires submitting a Stellar transaction, and each Stellar transaction has a minimum fee of \`0.00001\` \`XLM\`.

#### Persistence

By default, the when you launch a local instance of the Stellar network, the network state is *ephemeral*, and if you shut it down by closing the terminal process, all your data will be lost.

If you want your data to persist, decide on where you want to store the data, for example this is my local path I designated for persistent network storage.

~~~
/Users/sorobandev/network
~~~

Then, modify this \`docker run\` command with your selected path in the \`-v\` flag.

~~~sh:::6
docker run \\
  --rm \\
  -it \\
  --platform linux/amd64 \\
  -p 8000:8000 \\
  -v "YOUR_PATH:/opt/stellar" \\
  --name stellar \\
  stellar/quickstart:soroban-dev@sha256:8968d5c3344fe447941ebef12c5bede6f15ba29b63317a488c16c5d5842e4a71 \\
  --standalone \\
  --enable-soroban-rpc
~~~

The first time you run this command, it will ask you to provide a password for the PostgreSQL database. Once that's done, you'll have a persistent version of the local Stellar network.

Of course the ultimate form of persistence is the public blockchain. Thankfully, most of what we learned in this guide applies to working with the Stellar *Futurenet*, which is currently the only public blockchain that supports Soroban.

#### Futurenet

To be able to interact with Futurenet, we'll need to use \`docker run\` to launch the same set of processes, but this time connected to the public Futurenet blockchain.

~~~sh
docker run \\
  --rm \\
  -it \\
  --platform linux/amd64 \\
  -p 8000:8000 \\
  --name stellar \\
  stellar/quickstart:soroban-dev@sha256:8968d5c3344fe447941ebef12c5bede6f15ba29b63317a488c16c5d5842e4a71 \\
  --futurenet \\
  --enable-soroban-rpc
~~~

All processes except for *Friendbot*, which will now be accessible through a public URL because it's serving everyone that needs \`XLM\` on Futurenet, not just our local environment. To fund an account on Futurenet with the name keypair as before, use this command. As before, change the \`addr\` value to the public key of a different keypair.

~~~sh
curl "https://friendbot-futurenet.stellar.org/?addr=GALJMHIIE7LQHDMRKYLBPJ7WNAQP3V3WJUE35J46U62MM5RM7OKXIWWW"
~~~

We can now deploy our contract, with the only difference from before being the \`--network-passphrase\`.

~~~sh
soroban deploy \\
  --wasm target/wasm32-unknown-unknown/release/project.wasm \\
  --secret-key SAEWT32IC2A77HNKNI6FJA2YQEJN24SDH6U3QVLFW2QEPQ5JZMENNCCD \\
  --rpc-url http://localhost:8000/soroban/rpc \\
  --network-passphrase 'Test SDF Future Network ; October 2022'
~~~

Once you have the contract \`ID\`, it's off to the races.

~~~
success
42539c0b3995870bd46b65264592c9cdb76e8b1b41cffa044d4c0f7442863df3
~~~

To invoke the contract function \`add\`, we only need to change the \`--id\` to the Futurenet contract \`ID\`.

~~~sh
soroban invoke \\
  --rpc-url http://localhost:8000/soroban/rpc \\
  --network-passphrase 'Test SDF Future Network ; October 2022' \\
  --secret-key SAEWT32IC2A77HNKNI6FJA2YQEJN24SDH6U3QVLFW2QEPQ5JZMENNCCD \\
  --id 42539c0b3995870bd46b65264592c9cdb76e8b1b41cffa044d4c0f7442863df3 \\
  --fn add \\
  --arg 40 \\
  --arg 2
~~~

And we get our final result.

~~~
success
42
~~~


[1]: https://www.docker.com/products/docker-desktop/
[2]: https://github.com/stellar/stellar-core
[3]: https://github.com/stellar/go/tree/master/services/horizon
[4]: https://github.com/stellar/go/tree/master/services/friendbot
[5]: https://github.com/stellar/go/tree/master/exp/services/soroban-rpc
[6]: https://developers.stellar.org/docs/fundamentals-and-concepts/stellar-data-structures/accounts
[7]: https://laboratory.stellar.org/#account-creator?network=futurenet
[8]: https://laboratory.stellar.org/#xdr-viewer
[9]: /guides/soroban-cli
`;

function StellarNetwork() {
  return (
    <Guide>
      <Annotation>
      This guide was created by following the <a href="https://soroban.stellar.org/docs/tutorials/deploy-to-local-network" target="_blank" rel="noreferrer">Deploy to a Local Network</a> and <a href="https://soroban.stellar.org/docs/tutorials/deploy-to-futurenet">Deploy to Futurenet</a> tutorials from the official Soroban documentation.
      </Annotation>
      <p className="fs-6 text-muted">December 5, 2022</p>
      <Markdown>
        {content}
      </Markdown>
      
      {/* <div className="card">
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">Next guide</h6>
          <Link className="btn btn-primary" to="/guides/soroban-cli">Public Blockchain</Link>
        </div>
      </div> */}
    </Guide>
  );
}

export {StellarNetwork};