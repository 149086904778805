import {DevelopmentEnvironment} from './DevelopmentEnvironment.jsx';
import {BasicContract} from './BasicContract.jsx';
import {Testing} from './Testing.jsx';
import {Building} from './Building.jsx';
import {SorobanCLI} from './SorobanCLI.jsx';
import {StellarNetwork} from './StellarNetwork.jsx';
import {RemoteTool} from './RemoteTool.jsx';

const guides = [
  {
    title: 'Development environment',
    path: '/guides/development-environment',
    component: DevelopmentEnvironment,
    timeCreated: '11/20/22'
  },
  {
    title: 'Basic Contract',
    path: '/guides/basic-contract',
    component: BasicContract,
    timeCreated: '11/20/22'
  },
  {
    title: 'Testing',
    path: '/guides/testing',
    component: Testing,
    timeCreated: '11/22/22'
  },
  {
    title: 'Building',
    path: '/guides/building',
    component: Building,
    timeCreated: '11/23/22'
  },
  {
    title: 'Soroban CLI',
    path: '/guides/soroban-cli',
    component: SorobanCLI,
    timeCreated: '11/24/22'
  },
  {
    title: 'Stellar Network',
    path: '/guides/stellar-network',
    component: StellarNetwork,
    timeCreated: '12/5/22'
  },
  {
    title: 'Remote Tool',
    path: '/guides/remote-tool',
    component: RemoteTool,
    timeCreated: '1/9/23'
  }
];

export default guides;