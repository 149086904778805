import {cx} from '@emotion/css';

const inflows = [
  {
    date: '11/21/2022',
    source: {
      url: 'https://github.com/stellar/sorobanathon/discussions/60',
      title: 'Soroban + Rust developer guides for JavaScript developers #60'
    },
    amount: 1000,
    currency: 'XLM',
    status: 'received'
  },
  {
    date: '11/22/2022',
    source: {
      url: 'https://github.com/stellar/sorobanathon/discussions/63',
      title: 'Guide to Rust + Soroban contract testing #63'
    },
    amount: 1000,
    currency: 'XLM',
    status: 'received'
  },
  {
    date: '11/23/2022',
    source: {
      url: 'https://github.com/stellar/sorobanathon/discussions/69',
      title: 'Guide to Soroban + Rust – building #69'
    },
    amount: 1000,
    currency: 'XLM',
    status: 'received'
  },
  {
    date: '11/24/2022',
    source: {
      url: 'https://github.com/stellar/sorobanathon/discussions/72',
      title: 'Guide to Soroban CLI #72'
    },
    amount: 1000,
    currency: 'XLM',
    status: 'received'
  },
  {
    date: '12/5/2022',
    source: {
      url: 'https://github.com/stellar/sorobanathon/discussions/111',
      title: 'Guide to Stellar Network #111'
    },
    amount: 1000,
    currency: 'XLM',
    status: 'received'
  },
  {
    date: '12/15/2022',
    source: {
      url: 'https://github.com/stellar/sorobanathon/discussions/159',
      title: 'Soroban Tool – Deploy Contracts in Browser #159'
    },
    amount: 1000,
    currency: 'XLM',
    status: 'awarded'
  }
];

const amountFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

function Funding() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h1>
            Funding
          </h1>
          <p className="lead">
            How this website manages to keep the lights on.
          </p>
          <p>
            I learned about Soroban through <a href="https://github.com/stellar/sorobanathon" target="_blank" rel="noreferrer">Sorobanathon</a>, which is an incentivized program for learning about Soroban. I'm listing all of my submissions below, and their status.
          </p>

          <h3>Sorobanathon Submissions</h3>

          <table className="table-light table-sm mt-2 mb-4">
            <thead>
              <tr>
                <th scope="col" className="text-end">Date</th>
                <th scope="col">Source</th>
                <th scope="col" className="text-end">Amount</th>
                <th scope="col">Currency</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {inflows.map((inflow, index) => {
                return (
                  <tr key={index}>
                    <td className="text-end">
                      {inflow.date}
                    </td>
                    <td>
                      {inflow.source.url &&
                        <a href={inflow.source.url} target="_blank" rel="noreferrer">
                          {inflow.source.title}
                        </a>
                      }
                    </td>
                    <td className="font-monospace text-end">
                      {inflow.amount !== undefined ? amountFormat.format(inflow.amount) : '-'}
                    </td>
                    <td className="font-monospace text-start">
                      {inflow.currency}
                    </td>
                    <td>
                      <span
                        className={cx({
                          'badge': true,
                          'text-bg-light': inflow.status === 'submitted',
                          'text-bg-dark': inflow.status === 'awarded',
                          'text-bg-success': inflow.status === 'received',
                        })}
                      >
                        {inflow.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>

            </tfoot>
          </table>


          <p>
            Since every submission takes time, I'll be marking the status with one of 3 categories.
          </p>
          <ul>
            <li>
              <span className="badge text-bg-light">submitted</span> - a post for this item has been created.
            </li>
            <li>
              <span className="badge text-bg-dark">awarded</span> - the submission was reviewed and awarded.
            </li>
            <li>
              <span className="badge text-bg-success">received</span> - the award was received in the official <em>sorobandev.com</em> address.
            </li>
          </ul>

          <h3>
            Accounts
          </h3>

          <ol>
            <li>
              <span className="badge text-bg-secondary font-monospace me-2">
                5,031.099 XLM
              </span>
              <a
                href="https://stellar.expert/explorer/public/account/GCBJFSEZ5RL2F6C6INE7NRPKSTTHNBGB3262CSR5YABF5D6BIMMZGFDE"
                target="_blank"
                rel="noreferrer"
              >
                GCBJFSEZ5RL2F6C6INE7NRPKSTTHNBGB3262CSR5YABF5D6BIMMZGFDE
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export {Funding};