import {css, cx} from '@emotion/css';
import {NavLink as Link} from 'react-router-dom';

import {useNavigation} from './Navigation/Context.jsx';

import {Icon} from './Icons';

import {material} from '../utilities/colors';
import * as device from '../utilities/device.js';

const styles = {
  header: css({
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 16px 0',
    padding: '16px',
    borderBottom: `1px solid ${material.grey[300]}`,
    [device.media.desktop]: {
      padding: '16px 0',
    },
    [device.media.tablet]: {
      padding: '16px 0',
    },
  }),
  title: css({
    flex: '1',
    fontSize: '24px',
  }),
  logo: css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    margin: '0 8px 0 0',
    borderRadius: '8px',
    backgroundColor: material.deepPurple[500],
    color: material.grey[100],
    fontSize: '18px',
  }),
  annex: css({
    display: 'none',
    [device.media.mobile]: {
      display: 'block',
    }
  })
};

function Header(props) {
  const navigation = useNavigation();

  const onToggleNavigation = () => {
    if (navigation.isHidden) {
      navigation.show();
    } else {
      navigation.hide();
    }
  };

  return (
    <header className={cx(styles.header)}>
      <div className={cx(styles.title)}>
        <Link to="/" className="text-dark text-decoration-none">
          <span className={cx(styles.logo)}>
            SD
          </span>
          <span className="">sorobandev.com</span>
        </Link>
      </div>
      <div className={cx(styles.annex)}>
        <button className="btn" onClick={onToggleNavigation}>
          <Icon
            name={navigation.isHidden ? 'Menu' : 'Close'}
          />
        </button>
      </div>
    </header>
  );
}

export {Header};