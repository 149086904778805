import {NavLink as Link} from 'react-router-dom';

import {Guide} from './components/Guide.jsx';
import {Markdown} from '../../components/Markdown.jsx';
import {Annotation} from '../../components/Annotation.jsx';

const content = `
### Building

Before we can run or deploy a Soroban contract, we need to build it, using Cargo.

~~~sh
cargo build --release --target wasm32-unknown-unknown
~~~

#### \`build\`

Let's decipher the \`build\` command. First, it's one of the many [Cargo *build* commands][1], and it compiles your Rust code along with its dependencies. OK, but what does that mean?

As a JavaScript developer, you might be familiar with the concept of *building* your code, for example with **Webpack**. What Webpack does is it takes your JavaScript code, perhaps filled with new features like \`async\` and \`await\`, and translates it into an older, more compatible version of the same underlying JavaScript language.

The browser then interprets your JavaScript code as it downloads your website and starts executing it. We call that a *scripting* language, and it doesn't work without an interpreter.

Rust, along with Soroban, need to be compiled before it can be executed.

#### \`--release\`

By selecting a specific \`profile\` we're instructing the compiler to observe the requirements we outlined in our \`Cargo.toml\` file. You can refresh your memory on what each does in the [Development Environment](/guides/development-environment) guide.

~~~toml:Cargo.toml:18
[profile.release]
opt-level = "z"
overflow-checks = true
debug = 0
debug-assertions = false
strip = "symbols"
panic = "abort"
codegen-units = 1
lto = true
~~~

#### \`--target wasm32-unknown-unknown\`

With the [\`--target\`][2] option you select which *architecture* you want to compile your Rust + Soroban code for. Without providing a target option Cargo defaults to compiling it for the host architecture, aka your MacBook, Linux or Windows computer. Then, you can execute it like any other executable.

With \`wasm32-unknown-unknown\` we specify the \`<architecture>-<vendor>-<system>-<abi>\`. Ignoring the \`unknown\` bits, we have selected our build target to be \`wasm32\` or WebAssembly. More about WebAssembly support in Rust [here][3].

### WebAssembly

So what's WebAssembly? In short, it's a low-level assembly-like language that can run with near-native performance in modern browsers. It was designed to enable languages like C++ and Rust to run on the web stack and interface with JavaScript and the DOM. You can learn moore on [MDN][4].

The reason why Soroban contract code compiles to WebAssembly is because Soroban is using the WebAssembly (WASM) runtime for smart contract execution on the blockchain. Here's a quote from the Stellar blog describing the decision making process.

> WASM lends itself well to smart contracts as it was designed to operate in an environment that, much like a blockchain, is extremely adversarial: the web. As a VM, WASM presents the widest set of possible source languages, toolchains, interpreters and JITs, and presents the best performance and lowest degree of coupling to a specific blockchain's semantics. However, it leaves open the problem of designing “the rest of the stack” - blockchain agnostic and specific runtime services, as well as source language APIs/SDKs and/or special bindings/domain specific languages. There are several chains to study and learn from, but none fits Stellar well enough to adopt unchanged. *https://stellar.org/blog/project-jump-cannon-choosing-wasm?locale=en|Tomer Weller|stellar.org/blog*

OK, so let's go ahead and compile the following Soroban contract.

~~~rust:lib.rs
#![no_std]

use soroban_sdk::{contractimpl};

pub struct CalculatorContract;

#[contractimpl]
impl CalculatorContract {
    pub fn add(left: i64, right: i64) -> i64 {
        left + right
    }
}
~~~

Execute the build command.

~~~sh
cargo build --release --target wasm32-unknown-unknown
~~~

It will take some time and you should something similar in your terminal.

~~~
Compiling proc-macro2 v1.0.47
Compiling quote v1.0.21
…
Compiling soroban-sdk-macros v0.2.1
Compiling soroban-sdk v0.2.1
Compiling project v0.1.0 (/path/to/your/project)
 Finished release [optimized] target(s) in 21.62s
~~~

These are incremental builds, so only the first time takes so long, let's say you change some code in your \`lib.rs\`, running the same build command will take less than a second.

~~~
Compiling project v0.1.0 (/path/to/your/project)
 Finished release [optimized] target(s) in 0.24s
~~~

#### Exploring the Build

I'm sure you're as anxious as me to see what the end result looks like. Well, look into your project folder, you should see a new directory called \`target\`, let's open it.

~~~
…
target
|-- wasm32-unknown-unknown
    |-- release
        |-- project.wasm
…
~~~

The \`project.wasm\` file is your compiled Rust + Soroban contract, but because it's in binary you can't open it with a simple text editor. Thankfully, the people at WebAssembly have created a [website that can parse .wasm files][5] into something more legible - WebAssembly Text format or WAT for short. Simply upload your \`project.wasm\` file the and you'll see the WAT version of your binary code.

~~~wat:project.wasm
(module
  (type $t0 (func (param i64) (result i64)))
  (type $t1 (func (param i32 i64)))
  (type $t2 (func (param i64 i64) (result i64)))
  (type $t3 (func))
  (import "i" "0" (func $i.0 (type $t0)))
  (import "i" "_" (func $i._ (type $t0)))
  (func $f2 (type $t1) (param $p0 i32) (param $p1 i64)
    (local $l2 i64) (local $l3 i64)
    (local.set $l2
      (i64.const 1))
    (block $B0
      (block $B1
        (br_if $B1
          (i64.eqz
            (i64.and
              (local.get $p1)
              (i64.const 1))))
        (br_if $B0
          (i32.ne
            (i32.wrap_i64
              (local.get $p1))
            (i32.const 55)))
        (local.set $l2
          (i64.const 0))
        (local.set $l3
          (call $i.0
            (local.get $p1)))
        (br $B0))
      (local.set $l3
        (i64.shr_u
          (local.get $p1)
          (i64.const 1)))
      (local.set $l2
        (i64.const 0)))
    (i64.store offset=8
      (local.get $p0)
      (local.get $l3))
    (i64.store
      (local.get $p0)
      (local.get $l2)))
  (func $add (export "add") (type $t2) (param $p0 i64) (param $p1 i64) (result i64)
    (local $l2 i32)
    (global.set $g0
      (local.tee $l2
        (i32.sub
          (global.get $g0)
          (i32.const 32))))
    (call $f2
      (i32.add
        (local.get $l2)
        (i32.const 16))
      (local.get $p0))
    (block $B0
      (block $B1
        (br_if $B1
          (i32.load offset=16
            (local.get $l2)))
        (local.set $p0
          (i64.load offset=24
            (local.get $l2)))
        (call $f2
          (local.get $l2)
          (local.get $p1))
        (br_if $B1
          (i32.wrap_i64
            (i64.load
              (local.get $l2))))
        (br_if $B0
          (i32.ne
            (i64.lt_s
              (local.tee $p1
                (i64.load offset=8
                  (local.get $l2)))
              (i64.const 0))
            (i64.lt_s
              (local.tee $p1
                (i64.add
                  (local.get $p0)
                  (local.get $p1)))
              (local.get $p0))))
        (block $B2
          (block $B3
            (br_if $B3
              (i64.gt_s
                (local.get $p1)
                (i64.const -1)))
            (local.set $p0
              (call $i._
                (local.get $p1)))
            (br $B2))
          (local.set $p0
            (i64.shl
              (local.get $p1)
              (i64.const 1))))
        (global.set $g0
          (i32.add
            (local.get $l2)
            (i32.const 32)))
        (return
          (local.get $p0)))
      (unreachable)
      (unreachable))
    (call $f4)
    (unreachable))
  (func $f4 (type $t3)
    (call $f5)
    (unreachable))
  (func $f5 (type $t3)
    (unreachable)
    (unreachable))
  (func $_ (export "_") (type $t3))
  (memory $memory (export "memory") 16)
  (global $g0 (mut i32) (i32.const 1048576))
  (global $__data_end (export "__data_end") i32 (i32.const 1048576))
  (global $__heap_base (export "__heap_base") i32 (i32.const 1048576)))
~~~

I won't go too deep into WebAssembly here, I just want to show how our contract code gets compiled. Specifically, I want to show how our Soroban \`add()\` function looks in WebAssembly. First, we can find the type definition of the the function.

~~~wat:project.wasm:4
(type $t2 (func (param i64 i64) (result i64)))
~~~

Lot's of parentheses, I know, but if you squint you can see a resemblence to the Rust definition. The function type declares two arguments/parameters, both of type \`i64\` or 64-bit signed integers, and return/result value that's also \`i64\`.

~~~rust:lib.rs:9
pub fn add(left: i64, right: i64) -> i64
~~~

Then, a lot like the Rust code, we have the implementation of the function, we even can find the name \`"add"\`.

~~~wat:project.wasm:42
(func $add (export "add") (type $t2) (param $p0 i64) (param $p1 i64) (result i64)
~~~

With the actual addition happening here. In essence, we execute the \`i64.add\` instruction which takes two \`i64\` values from the stack and pushes their sum back onto the top of the stack, as per the [docs][10].

~~~wat:project.wasm:78
(i64.add
  (local.get $p0)
  (local.get $p1)))
~~~

The function body itself is over 60 lines long, yeah, over 60 lines of code just to add two numbers! But that's what you get with assembly like languages, they're all very verbose. But hey, the total size of the contract as compiled is only \`481\` bytes, and that's what matters in the end.

If you want to read up more on WebAssembly, here are some useful resources.

- [Understanding WebAssembly text format][6]
- [Understanding WebAssembly in one article][9]
- [Writing WebAssembly By Hand][7]
- [WABT: The WebAssembly Binary Toolkit][8]

#### Soroban Contract Artifact

What we have here in the \`project.wasm\` file is the entire Soroban contract logic. You will need it for all kinds of things.

- Deploying the contract to the blockchain
- Sharing the contract interface
- Running integration tests against the contract

[1]: https://doc.rust-lang.org/cargo/commands/cargo-build.html
[2]: https://doc.rust-lang.org/cargo/commands/cargo-build.html#compilation-options
[3]: https://rustwasm.github.io/docs/book/reference/add-wasm-support-to-crate.html
[4]: https://developer.mozilla.org/en-US/docs/WebAssembly
[5]: https://webassembly.github.io/wabt/demo/wasm2wat/
[6]: https://developer.mozilla.org/en-US/docs/WebAssembly/Understanding_the_text_format
[7]: https://blog.scottlogic.com/2018/04/26/webassembly-by-hand.html
[8]: https://github.com/WebAssembly/wabt#running-wasm2wat
[9]: https://www.alibabacloud.com/blog/understand-webassembly-in-one-article_599282
[10]: https://developer.mozilla.org/en-US/docs/WebAssembly/Reference/Numeric/Addition
`;



function Building() {
  return (
    <Guide>
      <Annotation>
      This guide was created by following the <a href="https://soroban.stellar.org/docs/tutorials/build" target="_blank" rel="noreferrer">Build</a> tutorial from the official Soroban documentation.
      </Annotation>
      <p className="fs-6 text-muted">November 23, 2022</p>
      <Markdown>
        {content}
      </Markdown>
      
      <div className="card">
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">Next guide</h6>
          <Link className="btn btn-primary" to="/guides/soroban-cli">Soroban CLI</Link>
        </div>
      </div>
    </Guide>
  );
}

export {Building};