import {useContext, useState} from 'react';

import {Context} from '../Context.jsx';

import {Method} from '../Method';

import {Text} from '../../../../components/inputs';

function GetTransactionStatus(props) {
  const context = useContext(Context);
  
  const [id, setId] = useState(context.parameters.id);

  const request = {
    method: 'getTransactionStatus',
    parameters: {
      hash: id
    }
  };

  const parameters = [
    <Text
      name="id"
      placeholder="Transaction ID"
      description="Stellar transaction ID, encoded in hexadecimal."
      value={id}
      onChange={(nextId) => setId(nextId)}
    />
  ];

  return (
    <Method
      name="getTransactionStatus"
      parameters={parameters}
      description="Call this to check if your transaction has been executed."
      request={request}
    />
  );
}

export {GetTransactionStatus};