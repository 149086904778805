import {Wrapper} from './components/Wrapper.jsx';

function File(props) {
  return (
    <Wrapper label={props.placeholder}>
      <div className="vstack gap-1">
        <div>
          <input
            type="file"
            className="form-control"
            placeholder={props.placeholder}
            onChange={(event) => props.onChange(event)}
          />
        </div>
        {props.isBinary && props.value &&
          <div>
            <span className="badge bg-secondary me-2">Size</span>
            <code>{props.value.length}B</code>
          </div>
        }
      </div>
    </Wrapper>
  );
}

export {File};