import {css, cx} from '@emotion/css';

import {material} from '../utilities/colors';
import * as device from '../utilities/device.js';

const styles = {
  footer: css({
    margin: '16px 0 0 0',
    padding: '16px',
    borderTop: `1px solid ${material.grey[300]}`,
    color: material.grey[500],
    [device.media.desktop]: {
      padding: '16px 0',
    },
    [device.media.tablet]: {
      padding: '16px 0',
    },
  })
};

function Footer(props) {
  return (
    <footer className={cx(styles.footer)}>
      Built by <a href="https://twitter.com/sorobandev" target="_blank" rel="noreferrer">Morgan Wilde</a>
    </footer>
  );
}

export {Footer};