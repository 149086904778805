import {useEffect, useState} from 'react';
import {css, cx} from '@emotion/css';

import {Wrapper} from '../components/Wrapper.jsx';
import {Text} from '../Text.jsx';
import {Options} from '../Options.jsx';

import {encode} from './encode.js';

import {material} from '../../../utilities/colors';

const types = [
  {
    value: 'scvU63',
    title: 'UInt63'
  },
  {
    value: 'scvU32',
    title: 'UInt32',
  },
  {
    value: 'scvI32',
    title: 'Int32',
  },
  {
    value: 'scvStatic',
    title: 'Static',
  },
  {
    value: 'scvObject',
    title: 'Object',
  },
  {
    value: 'scvSymbol',
    title: 'Symbol',
  },
  {
    value: 'scvBitset',
    title: 'Bits',
  },
  {
    value: 'scvStatus',
    title: 'Status',
    isDisabled: true
  },
];
const staticTypes = [
  {
    value: 'scsVoid',
    title: 'Void'
  },
  {
    value: 'scsTrue',
    title: 'True'
  },
  {
    value: 'scsFalse',
    title: 'False'
  },
  {
    value: 'scsLedgerKeyContractCode',
    title: 'Ledger key contract code'
  }
];
const objectTypes = [
  {
    value: 'scoVec',
    title: 'Vector',
    isDisabled: false
  },
  {
    value: 'scoMap',
    title: 'Map',
    isDisabled: true
  },
  {
    value: 'scoU64',
    title: 'UInt64'
  },
  {
    value: 'scoI64',
    title: 'Int64'
  },
  {
    value: 'scoU128',
    title: 'UInt128',
    isDisabled: true
  },
  {
    value: 'scoI128',
    title: 'Int128',
    isDisabled: true
  },
  {
    value: 'scoBytes',
    title: 'Bytes'
  },
  {
    value: 'scoContractCode',
    title: 'Contract code',
    isDisabled: true
  },
  {
    value: 'scoAccountId',
    title: 'Account ID'
  },
];

const constants = {
  typeInputs: {
    text: ['scvU32', 'scvI32'],
  },
  bitExpression: /([01]+)/
};

const styles = {
  row: css({
    '& > :first-child:not(:last-child)': {
      flex: '0 0 200px !important',
    },
    '& > :nth-child(2):not(:last-child)': {
      flex: '0 0 200px !important',
    }
  }),
  type: css({
    flex: 'auto !important',
    color: material.grey[700],
    backgroundColor: material.grey[200],
  }),
  nestedRow: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '0 0 8px 0',
  }),
  nestedIndex: css({
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    width: '38px',
    height: '38px',
    // margin: '0 8px 0 0',
    borderRadius: '8px',
    // backgroundColor: material.deepPurple[100],
    color: material.grey[400],
  }),
  nestedInput: css({
    flex: '1',
  }),
  nestedControls: css({
    margin: '0 8px 0 0px',
  }),
};

function isEmpty(value) {
  if (value === undefined || value === null || value.raw === undefined || value.raw === null) {
    return true;
  } else if (typeof value.raw === 'object') {
    return isEmpty(value.raw);
  } else {
    return (
      typeof value.raw === 'string' &&
      value.raw.length === 0
    );
  }
}

function SorobanValue(props) {
  const [error, setError] = useState(null);

  let input = [];
  let nestedInput = [];

  const onChange = (nextValue) => {
    setError(null);

    if (!props.isNested) {
      let encoded = {};

      try {
        encoded = encode(nextValue);
      } catch (error) {
        if (!isEmpty(nextValue)) {
          setError(error.message || error);
        } else {
          setError(null);
        }
      }
      
      if (props.onChange) {
        props.onChange({
          ...nextValue,
          ...encoded
        });
      }
    } else {
      props.onChange(nextValue);
    }
  };

  const onAddNested = () => {
    const nested = props.value.raw.raw ? [...props.value.raw.raw] : [];
    const nextNested = [...nested, null];

    onChange({
      ...props.value,
      raw: {
        ...props.value.raw,
        raw: nextNested
      }
    });
  };

  const onRemoveNested = (index) => {
    const nextNested = [...props.value.raw.raw];
    nextNested.splice(index, 1);

    onChange({
      ...props.value,
      raw: {
        ...props.value.raw,
        raw: nextNested
      }
    });
  };

  useEffect(() => {
    if (props.value?.encoded === undefined) {
      onChange(props.value);
    }
  }, [props.value?.encoded]);

  //console.log(props);
  if (props.value && props.value.type) {
    if (props.value.type === 'scvU63') {
      input.push(
        <Text
          key={props.value.type}
          isGrouped={true}
          hasFloatingLabel={false}
          value={props.value.raw}
          onChange={(nextValue) => {
            let bigNumber = null;
            try {
              bigNumber = BigInt(nextValue);
            } catch (error) {
              // console.log(error);
            }
            
            onChange({
              type: props.value.type,
              raw: bigNumber === null ? '' : bigNumber.toString()
            });
          }}
        />
      );
    } else if (constants.typeInputs.text.includes(props.value.type)) {
      input.push(
        <Text
          key={props.value.type}
          isGrouped={true}
          hasFloatingLabel={false}
          placeholder="1234..."
          value={props.value.raw}
          onChange={(nextValue) => {
            onChange({
              type: props.value.type,
              raw: nextValue
            });
          }}
        />
      );
    } else if (props.value.type === 'scvSymbol') {
      input.push(
        <Text
          key={props.value.type}
          isGrouped={true}
          hasFloatingLabel={false}
          placeholder="ABCdef123..."
          value={props.value.raw}
          onChange={(nextValue) => {
            onChange({
              type: props.value.type,
              raw: nextValue.length <= 10 ? nextValue : nextValue.slice(0, 10)
            });
          }}
        />
      );
    } else if (props.value.type === 'scvBitset') {
      input.push(
        <Text
          key={props.value.type}
          isGrouped={true}
          hasFloatingLabel={false}
          placeholder="1010..."
          value={props.value.raw}
          onChange={(nextValue) => {
            const bits = constants.bitExpression.exec(nextValue);
            
            onChange({
              type: props.value.type,
              raw: bits && bits[1] === bits.input ? nextValue : props.value.raw
            });
          }}
        />
      );
    } else if (props.value.type === 'scvStatic') {
      input.push(
        <Options
          key="scvStatic"
          isGrouped={true}
          hasFloatingLabel={false}
          placeholder="Type"
          options={staticTypes}
          value={props.value.raw}
          onChange={(nextValue) => onChange({
            type: props.value.type,
            raw: nextValue
          })}
        />
      );
    } else if (props.value.type === 'scvObject') {
      input.push(
        <Options
          key="scvObject"
          className={cx(styles.type)}
          isGrouped={true}
          hasFloatingLabel={false}
          placeholder="Type"
          options={objectTypes}
          value={props.value.raw?.type}
          onChange={(nextValue) => onChange({
            type: props.value.type,
            raw: {
              type: nextValue
            }
          })}
        />
      );
      
      if (props.value.raw?.type) {
        switch (props.value.raw.type) {
          case 'scoVec': {
            const vector = props.value.raw.raw || [];

            const onVectorChange = (mutatedIndex, nextValue) => {
              const nextVector = vector.map((value, index) => {
                if (index === mutatedIndex) {
                  return nextValue;
                } else {
                  return value;
                }
              });

              onChange({
                ...props.value,
                raw: {
                  ...props.value.raw,
                  raw: nextVector
                }
              });
            };

            for (let i = 0; i < vector.length; i += 1) {
              const value = vector[i];

              nestedInput.push(
                <SorobanValue
                  key={`scoVec.${i}`}
                  isNested={true}
                  value={value}
                  onChange={(nextValue) => onVectorChange(i, nextValue)}
                />
              );
            }
            
            break;
          }
          case 'scoMap': {
            break;
          }
          case 'scoU64':
          case 'scoI64':
          case 'scoU128':
          case 'scoI128': {
            input.push(
              <Text
                key={`scvObject.${props.value.raw.type}`}
                isGrouped={true}
                hasFloatingLabel={false}
                value={props.value.raw.raw}
                onChange={(nextValue) => {
                  let bigNumber = null;
                  try {
                    bigNumber = BigInt(nextValue);
                  } catch (error) {
                    // console.log(error);
                  }
                  
                  onChange({
                    type: props.value.type,
                    raw: {
                      type: props.value.raw.type,
                      raw: bigNumber === null ? '' : bigNumber.toString()
                    }
                  });
                }}
              />
            );
            break;
          }
          case 'scoBytes': {
            input.push(
              <Text
                key={`scvObject.${props.value.raw.type}`}
                isGrouped={true}
                hasFloatingLabel={false}
                placeholder="Base 64 string..."
                value={props.value.raw.raw}
                onChange={(nextValue) => {
                  onChange({
                    type: props.value.type,
                    raw: {
                      type: props.value.raw.type,
                      raw: nextValue
                    }
                  });
                }}
              />
            );
            break;
          }
          case 'scoContractCode': {
            break;
          }
          case 'scoAccountId': {
            input.push(
              <Text
                key={`scvObject.${props.value.raw.type}`}
                isGrouped={true}
                hasFloatingLabel={false}
                placeholder="G..."
                value={props.value.raw.raw}
                onChange={(nextValue) => {
                  onChange({
                    type: props.value.type,
                    raw: {
                      type: props.value.raw.type,
                      raw: nextValue
                    }
                  });
                }}
              />
            );
            break;
          }
          default:
            break;
        }
      }
    }
  }

  return (
    <>
      <Wrapper label={props.label || props.placeholder} error={error}>
        <div className={cx('input-group', styles.row)}>
          <Options
            className={cx(styles.type)}
            isGrouped={true}
            hasFloatingLabel={false}
            placeholder="Type"
            options={types}
            value={props.value?.type}
            onChange={(nextValue) => onChange({
              type: nextValue
            })}
          />
          {input}
        </div>
      </Wrapper>
      {props.value?.raw?.type === 'scoVec' &&
        <div style={{padding: '8px 0 0 8px'}}>
          {nestedInput?.map((input, index) => {
            return (
              <div key={`nested.${index}`} className={cx(styles.nestedRow)}>
                <div className={cx(styles.nestedIndex)}>
                  {index}
                </div>
                <div className={cx(styles.nestedControls)}>
                  <button
                    className="btn btn-outline-danger"
                    onClick={onRemoveNested.bind(null, index)}
                  >
                    ✕
                  </button>
                </div>
                <div className={cx(styles.nestedInput)}>
                  {input}
                </div>
              </div>
            );
          })}
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={onAddNested}
            >
              add
            </button>
          </div>
        </div>  
      }
    </>
  );
}

export {SorobanValue};