import {css, cx} from '@emotion/css';
import {NavLink as Link} from 'react-router-dom';

import guides from '../pages/guides';

import {material} from '../utilities/colors';

const styles = {
  timestamp: css({
    margin: '4px 8px 0 0',
    color: material.grey[500],
    fontSize: '12px',
  })
};

function Timestamp(props) {
  return (
    <span className={cx(styles.timestamp)}>
      {props.children}
    </span>
  );
}

function Home() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <p className="lead">
            A web developer's guide to Soroban
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h2>Guides</h2>
          <p>
            Soroban is the new Smart Contract framework for the <a href="https://stellar.org/">Stellar</a> blockchain. Soroban is built on Rust. Both are unfamiliar to be, which is why I created this website. The following guides detail my journey of learning Soroban alongside the Rust programming language.
          </p>
          <ul className="icon-list ps-0">
            {guides.map((guide, index) => {
              if (guide.path) {
                return (
                  <li key={index} className="d-flex align-items-start mb-1">
                    <Timestamp>{guide.timeCreated}</Timestamp>
                    <Link to={guide.path}>
                      {guide.title}
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={index} className="text-muted d-flex align-items-start mb-1">
                    <Timestamp>soon</Timestamp>
                    {guide.title}
                  </li>
                );
              }
            })}
          </ul>
        </div>

        <div className="col-md-6">
          <h2>Tools</h2>
          <p>
            Along with the guides, I'll be working on some developer tools. My primary goal here is to reduce time to result when working on Soroban contracts. It's very important to get to see that first result on screen when learning something new.
          </p>
          <ul className="icon-list ps-0">
            <li className="d-flex align-items-start mb-1">
              <Link to="/tools/remote">
                Remote
              </Link>
            </li>
            <li className="d-flex align-items-start mb-1">
              <Link to="/tools/contract">
                Contract
              </Link>
            </li>
          </ul>
        </div>

        <div className="col-md-6">
          <h2>Organization</h2>
          <p>
            For any initiative to last it needs people and resources. I'll be composing data on both here for public access.
          </p>
          <ul className="icon-list ps-0">
            <li className="d-flex align-items-start mb-1">
              <Link to="/dao/funding">
                Funding
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <h2>Apps</h2>
          <p>
            Standalone apps built on Soroban.
          </p>
          <ul className="icon-list ps-0">
            <li className="d-flex align-items-start mb-1">
              <Link to="/apps/game">
                Game
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export {Home};