import {useContext} from 'react';
import {css, cx, keyframes} from '@emotion/css';

import {GameContext} from '../Context.jsx';

import {material} from '../../../../utilities/colors/material.js';

const settings = {
  grid: {
    length: 5
  }
};

const animations = {
  blink: keyframes({
    '0%': {
      opacity: '1'
    },
    '50%': {
      opacity: '0.5'
    },
    '100%': {
      opacity: '1'
    }
  })
};

const styles = {
  grid: css({
    display: 'grid',
    maxWidth: '500px',
    gridTemplateColumns: `repeat(${settings.grid.length}, 1fr)`,
    gap: '4px',
    margin: '16px 0 16px 0',
  }),
  cell: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: '1 / 1',
    backgroundColor: material.grey[200],
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: material.orange[50]
    }
  }),
  activeCell: css({
    backgroundColor: `${material.orange[200]} !important`,
  }),
  loadingCell: css({
    opacity: '1',
    animation: `${animations.blink} 1000ms ease infinite`,
  })
};

function VisualGrid(props) {
  const gameContext = useContext(GameContext);
  const toggleCell = (cell) => {
    props.onChange(props.grid.toggle(cell));
  };
  
  return (
    <div className={cx(styles.grid)}>
      {props.grid.cells.map((cell, index) => {
        return (
          <div
            key={index}
            className={cx({
              [styles.cell]: true,
              [styles.activeCell]: props.grid.cells[index] === 1,
              [styles.loadingCell]: gameContext.isLoading || gameContext.isSaving
            })}
            onClick={toggleCell.bind(null, index)}
          />
        );
      })}
    </div>
  );
}

export {VisualGrid};