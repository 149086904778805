import {createContext, useReducer, useEffect} from 'react';

import {useSoroban} from '../../../packages/soroban/Soroban.jsx';

import {Account} from '../../../packages/stellar/Account.js';
import {Keys} from '../../../packages/stellar/Keys.js';
import {GameContract} from './GameContract.jsx';

const settings = {
  grid: {
    length: 5
  },
  contractId: '7d8d9dd8b91e1bd12ab4d9bd6960514d826ab97a2a71397365eb5909fa7bf327',
  account: 'SBBLHHOHKTKTB3ODC4YKGN5J34RO2EYEAD4ZLMEAD2WQUSUUDK6AAP4B',
  // account: 'SBOSWT3G5AERIAEOITEN27GW3BGU3ANP7X3DHVEUMC6AWGHWRJKBZXQS',
};

function reducer(state, action) {
  switch (action.type) {
    case 'startLoading': {
      return {
        ...state,
        isLoading: true
      };
    }
    case 'stopLoading': {
      return {
        ...state,
        isLoading: false
      };
    }
    case 'startTransaction': {
      return {
        ...state,
        isSaving: true
      };
    }
    case 'completeTransaction': {
      return {
        ...state,
        isSaving: false
      };
    }
    case 'setGrids': {
      return {
        ...state,
        grids: action.payload
      };
    }
    case 'setPendingTransaction': {
      return {
        ...state,
        pendingTransaction: action.payload
      };
    }
    case 'setAccount': {
      return {
        ...state,
        account: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

const GameContext = createContext();

function GameProvider(props) {
  const soroban = useSoroban();
  const gameContract = new GameContract(
    soroban.network,
    settings.contractId
  );
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isSaving: false,
    grids: null,
    pendingTransaction: null,
    // account: null,
    account: Account.fromKey('SBBLHHOHKTKTB3ODC4YKGN5J34RO2EYEAD4ZLMEAD2WQUSUUDK6AAP4B')
  });

  const load = async () => {
    if (state.account) {
      dispatch({type: 'startLoading'});
      const loadedGrids = await gameContract.load(soroban.network, state.account);
      if (loadedGrids) {
        dispatch({
          type: 'setGrids',
          payload: loadedGrids
        });
      } else {
        dispatch({
          type: 'setGrids',
          payload: []
        });
      }
      dispatch({type: 'stopLoading'});
    }
  };

  useEffect(() => {
    if (state.account) {
      load();
    }
  }, [soroban, soroban.network, state.account]);

  const add = async (grid) => {
    dispatch({type: 'startTransaction'});
    try {
      const result = await state.account.call(
        gameContract.add(grid.encoded())
      );

      dispatch({
        type: 'setPendingTransaction',
        payload: result
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTransactionStatus = async (transaction) => {
    const response = await soroban.network.getTransactionStatus(transaction.id);
    console.log(response);
    if (response.status === 'pending') {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      fetchTransactionStatus(transaction);
    } else {
      dispatch({type: 'completeTransaction'});
    }
  };

  const login = (secretKey) => {
    const account = Account.fromKey(secretKey);
    dispatch({
      type: 'setAccount',
      payload: account
    });
  };

  useEffect(() => {
    if (state.pendingTransaction?.status === 'pending') {
      fetchTransactionStatus(state.pendingTransaction);
    }
  }, [state.pendingTransaction]);

  return (
    <GameContext.Provider
      value={{
        ...state,
        settings,
        load,
        add,
        login
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
}

export {
  GameContext,
  GameProvider
}