import {css, cx} from '@emotion/css';

import {Wrapper} from './components/Wrapper.jsx';

import {material} from '../../utilities/colors';

const styles = {
  type: css({
    flex: 'auto !important',
    color: material.grey[700],
    backgroundColor: material.grey[200],
  }),
  value: css({
    flex: '4 !important',
  })
};

function TypedText(props) {
  const type = props.value ? props.types.find((candidate) => candidate.name === props.value.type) : null;
  
  return (
    <Wrapper label={props.label || props.placeholder}>
      <div className="input-group">
        <select
          className={cx('form-select', styles.type)}
          value={props.value ? props.value.type : ''}
          onChange={(event) => {
            const nextType = props.types.find((candidate) => candidate.name === event.target.value);
            props.onChange({
              type: event.target.value,
              string: props.value && !nextType.isIdentity ? props.value.string : null
            })
          }}
        >
          <option value="" disabled>Type</option>
          {props.types.map((type, index) => {
            return (
              <option key={index} value={type.name}>
                {type.title}
              </option>
            );
          })}
        </select>
        {type && !type.isIdentity &&
          <input
            type="text"
            className={cx('form-control', styles.value)}
            value={props.value.string || ''}
            onChange={(event) => props.onChange({
              type: props.value.type,
              string: event.target.value
            })}
          />
        }
      </div>
    </Wrapper>
  );
}

export {TypedText};