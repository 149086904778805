import ReactMarkdown from 'react-markdown';
import {Link} from 'react-router-dom';

import {Code} from './Code.jsx';
import {Excerpt} from './Excerpt.jsx';

function findNodes(parent, tag) {
  if (parent.tagName === tag) {
    return parent;
  } else if (parent.children) {
    return parent.children.map((child) => findNodes(child, tag));
  }
}
function extractText(nodes) {
  return nodes
    .map((node) => {
      if (node.type === 'text') {
        return node.value;
      } else if (node.tagName !== 'em') {
        return extractText(node.children);
      } else {
        return '';
      }
    })
    .join('');
}

function Markdown(props) {
  return (
    <div className="">
      <ReactMarkdown
        children={props.children}
        components={{
          a: ({node, inline, className, children, ...props}) => {
            if (props.href.startsWith('/')) {
              return (
                <Link to={props.href}>
                  {children}
                </Link>
              );
            } else {
              return (
                <a
                  {...props}
                  target="_blank"
                  rel="noreferrer"
                >
                  {children}
                </a>
              );
            }
          },
          pre: ({node, inline, className, children, ...props}) => {
            return children;
          },
          code: ({node, inline, className, children, ...props}) => {
            if (inline) {
              return (
                <code>
                  {children}
                </code>
              );
            } else {
              if (className) {
                // const match = /language-(\w+)/.exec(className);
                const [language, filename, lineNumberOffset, highlights] = className.split(':');

                return (
                  <Code
                    source={children[0]}
                    language={language}
                    filename={filename && filename.length && filename.length > 0 ? filename : null}
                    lineNumberOffset={lineNumberOffset}
                    highlights={highlights ? highlights.split(',').map((number) => Number.parseInt(number)) : []}
                  />
                );
              } else {
                return (
                  <Code
                    source={children[0]}
                    highlights={[]}
                  />
                );
              }
            }
          },
          blockquote: ({node, inline, className, children, ...props}) => {
            const text = extractText(node.children);

            // Find source, if any.
            let source = null;
            const [sourceEm] = findNodes(node, 'em').flat().filter((candidate) => candidate);
            
            if (sourceEm) {
              const [url, author, title] = sourceEm.children[0].value.split('|');
              source = {
                url,
                title,
                author
              };
            }
            
            return (
              <Excerpt source={source}>
                {text}
              </Excerpt>
            );
          }
        }}
      />
    </div>
  );
}

export {Markdown};