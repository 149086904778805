import {Method} from '../../Method';

import {useParameters} from './useParameters.jsx';
import {useRequest} from './useRequest.jsx';

function SendTransaction(props) {
  const [state, parameters] = useParameters(true);
  const request = useRequest('sendTransaction', state);

  return (
    <Method
      name="sendTransaction"
      parameters={parameters}
      description={
        <>
          <p>
            Compose a transaction to install, create, or invoke a Soroban contract on the Stellar network.
          </p>
          <p>
            Every transaction is associated with a <code>sourceAccount</code>, which then needs to sign the transaction for the transaction to be executed on the ledger. For this purpose you will need to provide the account's <em>secret key</em>.
          </p>
        </>
      }
      request={request}
    />
  );
}

export {SendTransaction};