import {NavLink as Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {css, cx} from '@emotion/css';

const styles = {
  item: css({
    cursor: 'pointer',
  }),
};

function Tabs(props) {
  let tabs = [];
  if (props.children instanceof Array) {
    tabs = [...props.children];
  } else {
    tabs = [props.children];
  }

  const tab = tabs[props.activeTab];

  return (
    <>
      <div className="col-12">
        <ul className="nav nav-tabs">
          {tabs.map((tab, index) => {
            const hasContent = (
              (
                tab.props.children instanceof Array &&
                tab.props.children.filter((child) => (
                  child !== undefined &&
                  child !== null &&
                  child !== false
                )).length > 0
              ) ||
              (
                !(tab.props.children instanceof Array) &&
                tab.props.children !== null &&
                tab.props.children !== undefined
              )
            );

            return (
              <li
                key={index}
                className="nav-item"
                onClick={() => hasContent && props.onChangeTab(index)}
              >
                <span
                  className={cx({
                    'nav-link': true,
                    [styles.item]: true,
                    'active': index === props.activeTab,
                    'disabled': !hasContent
                  })}
                >
                  {tab.props.title}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="col-12">
        {tab}
      </div>
    </>
  );
}

function Tab(props) {
  return props.children;
}

export {Tabs, Tab};