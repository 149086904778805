import {css, cx} from '@emotion/css';

import {material} from '../utilities/colors';

const styles = {
  excerpt: css({
    margin: '16px 0',
  }),
  quote: css({
    margin: '8px 0',
    padding: '16px 16px',
    // border: `1px solid ${material.lightBlue[100]}`,
    borderLeft: `4px solid ${material.pink[300]}`,
    // borderRadius: '4px',
    backgroundColor: material.grey[50],
    fontSize: '16px',
  }),
  source: css({
    padding: '0 16px',
    borderLeft: `4px solid transparent`,
    color: material.grey[700],
    fontStyle: 'italic',
    fontSize: '14px',
  })
};

function Excerpt(props) {
  return (
    <div className={cx(styles.excerpt)}>
      <div className={cx(styles.quote)}>
        {props.children}
      </div>
      {props.source &&
        <div className={cx(styles.source)}>
          - {props.source.author}, <a href={props.source.url}>{props.source.title}</a>
        </div>
      }
    </div>
  );
}

export {Excerpt};