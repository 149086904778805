import {NavLink as Link } from 'react-router-dom';

import {Guide} from './components/Guide.jsx';
import {Markdown} from '../../components/Markdown.jsx';
import {Annotation} from '../../components/Annotation.jsx';

const content = `
### Basic Contract

After we've gone through and understood how to setup the specific Rust environment suited for Soroban smart contracts, we can start building our first contract.

#### Entry Point

Open up \`./src/lib.rs\`, which is the entry point to your library. That file, by default, contains this sample code.

~~~rust:lib.rs
pub fn add(left: usize, right: usize) -> usize {
    left + right
}

#[cfg(test)]
mod tests {
    use super::*;

    #[test]
    fn it_works() {
        let result = add(2, 2);
        assert_eq!(result, 4);
    }
}
~~~

I'm not going to dig into this code since not all of it is relevant to Soroban, so let's delete it and have an empty \`lib.rs\` file.

### Optimizations

We're going to assemble a basic Soroban contract from scratch.

~~~rust:lib.rs
#![no_std]
~~~

This ensures that the Rust standard library is not included in the binary. It's big so we save size.

But the hash \`#\` + bang \`!\` and \`[no_std]\` do not look familiar to us JavaScript developers.

Turns out this is an [Attribute][1] which is a Rust way of annotating declarations (code). These declarations are used by the compiler and as such are essentially code metadata. An *attribute* is declared using the hash \`#\`, but there are [two types][2].

- \`#[pink]\` annotates the next declaration.
- \`#![red]\` annotates the enclosing scope.

You can have your own attributes, not sure as to why yet, but there are also *built-in* [Rust attributes][3]. In this case \`no_std\` is an attribute that removes the std (standard library) from the *prelude*. What's a *prelude*?

#### Rust Prelude

*Prelude* is a list of things that Rust automatically imports into every program. We're talking about all kids of super [basic features][4] like \`clone\` and \`string\`, among others.

### Soroban SDK

~~~rust:lib.rs:2
use soroban_sdk::{contractimpl, symbol, vec, Env, Symbol, Vec};
~~~

This imports stuff from the Soroban SDK. The syntax is a little wonky, so let's look at how such an import would look in JavaScript.

~~~javascript
import {contractimpl, symbol, vec, Env, Symbol, Vec} from 'soroban_sdk';
~~~

Let's understand what we're importing in closer detail.

\`Env\`, \`Symbol\`, and \`Vec\` are basic Soroban types, we need those because we can't use the Rust standard library.

\`contractimpl\`, \`symbol\`, and \`vec\` are [procedural macros][5], which allow to run code at compile time that operates over Rust syntax. Very meta. Here's a link to the [implementation of the \`contractimpl\`][6] procedural macro.

### Soroban Contract

~~~rust:lib.rs:4
pub struct Contract;

#[contractimpl]
impl Contract {
    pub fn hello(env: Env, to: Symbol) -> Vec<Symbol> {
        vec![&env, symbol!("Hello"), to]
    }
}
~~~

Soroban smart contracts are defined as [Rust \`struct\`s][7]. The contract implementation has to be annotated with \`#[contractimpl]\`, which takes the Rust code we wrote and [transforms it][8] to code that Soroban can evaluate directly. One transformation is to enable calling functions marked with \`pub\` externally, meaning outside of the context of the contract code itself.

If you want access to the Soroban environment, make sure the first argument is of type \`Env\`.

That covers the Soroban part of the code above, now the Rust part.

~~~rust:lib.rs:4
pub struct Contract;
~~~

First, we have the \`pub\` prefix. By default, everything you declare in Rust is private and only accessible within the current module and its descendants. We need to expose our \`Contract\` to the outsde world, so \`pub\` is the keyword that marks it public. More about visibility and privacy [here][9].

After making it public we declare the type \`struct Contract\`. In Rust, structs are used to hold together multiple related values (data) with associated names, as per the [docs][10].

Next we get to an *attribute* annotation.

~~~rust:lib.rs:6
#[contractimpl]
~~~

The compiler will take the next declaration and feed it to the \`contractimpl\` procedural macro, which we imported earlier from \`soroban_sdk\`.

~~~rust:lib.rs:7
impl Contract
~~~

When you declare a \`struct\` in Rust you can't put any functions related to a \`struct\` along with its values. Instead, you implement functionality for a specific type using the \`impl\` keyword. Let's say you have a \`struct Car\` with make and model values in the declaration, in order to define \`drive()\` functionality you need to use \`impl\` and define the \`drive\` function there.

~~~rust:lib.rs:7
impl Contract {
  pub fn hello(env: Env, to: Symbol) -> Vec<Symbol> {
    // ...
  }
}
~~~

Moving along, we implement a public function \`hello\`, using the \`fn\` keyword. Functions in Rust take argument similar to JavaScript but you also have to define their types, so \`env: Env\` is the first argument of the \`hello\` function and its type is \`Env\`. If a function returns a value, we have to define its type with the arrow \`->\` syntax. In this case, \`hello\` returns a \`Vec<Symbol>\`. What are those angle brackets, huh?

#### Generics

Because Rust is a strongly typed language, everything has to have a type, it's not like JavaScipt where any argument, variable, etc. can take values of any type. This can be limiting in instances where a function or a type can work with a number of different types. For that reason, Rust has [*Generics*][11], which allow functions and structs to work with a generic type, for example \`fn hi<T>(argument: T)\` has one argument with a generic type.

All together, the function \`hello\` takes two arguments, one types \`Env\`, and another typed \`Symbol\`, and it returns a value of type \`Vec\` with the concrete type \`Symbol\` in place of a generic type \`T\`, as declared in the [SDK][12].

Finally, we complete the \`hello\` function by returning a value.

~~~rust:lib.rs:8
pub fn hello(env: Env, to: Symbol) -> Vec<Symbol> {
    vec![&env, symbol!("Hello"), to]
}
~~~

The \`vec!\` is a [Rust macro rule][13] as defined in the [Soroban SDK][14]. This macro takes an array of values and creates an instance of \`Vec\`.

First, we pass \`&env\`, which is a [Rust reference][15] to the \`env\` argument from the function above.

In JavaScript you have values types like numbers and strings, and reference types likes objects. In Rust, any type can have a reference created to it, which then allows to pass around just the reference to the original variable. To pass a reference to a value, simply prefix it with \`&\`, like \`&env\`.

Next we call another macro imported from the Soroban SDK, this time it's [\`symbol!\`][16]. This macro takes a string literal and converts it to a special Soroban type called \`Symbol\`.

Lastly, the last item we pass to \`vec!\` is the function argument \`to\`.

### Final Contract

~~~rust:lib.rs
#![no_std]
use soroban_sdk::{contractimpl, symbol, vec, Env, Symbol, Vec};

pub struct Contract;

#[contractimpl]
impl Contract {
    pub fn hello(env: Env, to: Symbol) -> Vec<Symbol> {
        vec![&env, symbol!("Hello"), to]
    }
}
~~~

This is the full version of the \`./src/lib.rs\` file after we implement our first Soroban contract.

[1]: https://doc.rust-lang.org/reference/attributes.html
[2]: https://web.mit.edu/rust-lang_v1.25/arch/amd64_ubuntu1404/share/doc/rust/html/book/first-edition/attributes.html
[3]: https://doc.rust-lang.org/reference/attributes.html#built-in-attributes-index
[4]: https://doc.rust-lang.org/std/prelude/
[5]: https://doc.rust-lang.org/reference/procedural-macros.html
[6]: https://github.com/stellar/rs-soroban-sdk/blob/main/soroban-sdk-macros/src/lib.rs#L63
[7]: https://doc.rust-lang.org/book/ch05-01-defining-structs.html
[8]: https://github.com/stellar/rs-soroban-sdk/blob/main/soroban-sdk-macros/src/lib.rs#L63
[9]: https://doc.rust-lang.org/reference/visibility-and-privacy.html#visibility-and-privacy
[10]: https://doc.rust-lang.org/book/ch05-01-defining-structs.html
[11]: https://doc.rust-lang.org/rust-by-example/generics.html
[12]: https://github.com/stellar/rs-soroban-sdk/blob/main/soroban-sdk/src/vec.rs#L118
[13]: https://doc.rust-lang.org/rust-by-example/macros.html
[14]: https://github.com/stellar/rs-soroban-sdk/blob/main/soroban-sdk/src/vec.rs#L36
[15]: https://doc.rust-lang.org/book/ch04-02-references-and-borrowing.html
[16]: https://github.com/stellar/rs-soroban-sdk/blob/main/soroban-sdk-macros/src/lib.rs#L44
`;

function BasicContract() {
  return (
    <Guide>
      <Annotation>
        This guide was created by following the <a href="https://soroban.stellar.org/docs/tutorials/write-a-contract" target="_blank" rel="noreferrer">Write a Contract</a> tutorial from the official Soroban documentation.
      </Annotation>
      <p className="fs-6 text-muted">November 20, 2022</p>
      <Markdown>
        {content}
      </Markdown>
      
      <div className="card">
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">Next guide</h6>
          <Link className="btn btn-primary" to="/guides/testing">Testing</Link>
        </div>
      </div>
    </Guide>
  );
}

export {BasicContract};