import Stellar from '../../../packages/soroban/xdr/stellar.js';

import * as hex from '../../../packages/utilities/hex.js';

import {Keys} from '../../../packages/stellar/Keys.js';
import {Transaction} from '../../../packages/stellar/Transaction/Transaction.js';
import * as Operation from '../../../packages/stellar/Transaction/Operation';

import {Grid} from './Grid.js';

const constants = {
  keys: {
    grid: (contractId) => Stellar.LedgerKey.contractData(
      new Stellar.LedgerKeyContractData({
        contractId: hex.decode(contractId),
        key: Stellar.ScVal.scvSymbol(
          new TextEncoder().encode('LIBRARY')
        )
      })
    )
  },
  grid: {
    length: 5
  }
};

class GameContract {
  constructor(contractId) {
    this.contractId = contractId;
  }
  async load(network, account) {
    const response = await network.executeRequest({
      method: 'getLedgerEntry',
      parameters: {
        key: constants.keys.grid(this.contractId).toXDR('base64')
      }
    });
    
    if (response?.xdr) {
      // TODO: Refactor for multiple grids.
      const ledgerEntry = Stellar.LedgerEntryData.fromXDR(response.xdr, 'base64');
      const ledgerValue = ledgerEntry?._value?._attributes?.val?._value;
      const library = ledgerValue._value.map((element) => {
        const keys = new Keys(
          element._attributes.key._value._value[1]._value._value._value
        );

        const grids = element._attributes.val._value._value.map((element) => {
          return Grid.fromInteger(element._value, constants.grid.length);
        });

        return {
          account: keys.formattedPublicKey,
          grids
        };
      });
      const accountLibrary = library.filter((library) => library.account === account.keys.formattedPublicKey);

      return accountLibrary.length === 1 ? accountLibrary[0].grids : null;
    }

    return null;
  }
  async add(grid, network, sourceAccount) {
    const encodedGrid = grid.encoded();

    // Simulate for the footprint.
    const transaction = await new Transaction()
      .sourceAccount(sourceAccount)
      .operation(
        new Operation.InvokeContract()
          .contractId(this.contractId)
          .functionName('add')
          .functionArgument(Stellar.ScVal.scvU32(encodedGrid))
      )
      .serializedFor(network);

    await transaction.signedBy(sourceAccount);
    const finalizedTransaction = transaction.finalized();

    const simulationResponse = await network.executeRequest({
      method: 'simulateTransaction',
      parameters: {
        transaction: finalizedTransaction
      }
    });
    
    // Send.
    if (simulationResponse?.footprint) {
      const transaction = await new Transaction()
        .sourceAccount(sourceAccount)
        .operation(
          new Operation.InvokeContract()
            .contractId(this.contractId)
            .functionName('add')
            .functionArgument(Stellar.ScVal.scvU32(encodedGrid))
            .footprint(Stellar.LedgerFootprint.fromXDR(simulationResponse.footprint, 'base64'))
        )
        .serializedFor(network);

      await transaction.signedBy(sourceAccount);
      const finalizedTransaction = transaction.finalized();

      const sendResponse = await network.executeRequest({
        method: 'sendTransaction',
        parameters: {
          transaction: finalizedTransaction
        }
      });

      if (sendResponse.id) {
        return sendResponse;
      } else {
        throw sendResponse.error;
      }
    }
  }
}

export {GameContract};