import {useState, useContext} from 'react';

import {Context} from '../Context.jsx';

import {Method} from '../Method';

import {Text} from '../../../../components/inputs';

function GetAccount(props) {
  const context = useContext(Context);
  
  const [address, setAddress] = useState(context.parameters.address || null);

  const request = {
    method: 'getAccount',
    parameters: {
      address
    }
  };

  const parameters = [
    <Text
      name="address"
      placeholder="Address"
      description={
        <>
          Stellar account address formatted as <code>G...</code>, same as the Public Key.
        </>
      }
      value={address}
      onChange={(nextAddress) => setAddress(nextAddress)}
    />
  ];

  return (
    <Method
      name="getAccount"
      parameters={parameters}
      description="Fetch the necessary account information to build a transaction."
      request={request}
    />
  );
}

export {GetAccount};