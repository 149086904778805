import {useMemo} from 'react';

import {useSoroban} from '../../../../../packages/soroban/Soroban.jsx';

import Stellar from '../../../../../packages/soroban/xdr/stellar.js';

import {Account} from '../../../../../packages/stellar/Account.js';
import {Transaction} from '../../../../../packages/stellar/Transaction/Transaction.js';
import * as Operation from '../../../../../packages/stellar/Transaction/Operation';
import {Code} from '../../../../../packages/soroban/Code/Code.js';

import {SorobanValue} from '../../../../../components/inputs';

function useRequest(method, props) {
  const soroban = useSoroban();

  const request = useMemo(
    async () => {
      let finalizedTransaction = null;

      try {
        switch (props.hostFunction) {
          case 'InstallContractCode': {
            const account = Account.fromKey(props.sourceAccount);
            const code = new Code(props.code);
            const transaction = await new Transaction()
              .sourceAccount(account)
              .operation(
                new Operation.InstallContractCode()
                  .code(code)
              )
              .serializedFor(soroban.network);
              
            await transaction.signedBy(account);
            finalizedTransaction = transaction.finalized();

            break;
          }
          case 'CreateContract': {
            const account = Account.fromKey(props.sourceAccount);
            const transaction = await new Transaction()
              .sourceAccount(account)
              .operation(
                new Operation.CreateContract()
                  .codeHash(props.codeHash)
                  .network(soroban.network)
                  .sourceAccount(account)
              )
              .serializedFor(soroban.network);

            await transaction.signedBy(account);
            finalizedTransaction = transaction.finalized();

            break;
          }
          case 'InvokeContract': {
            const account = Account.fromKey(props.sourceAccount);

            let functionArguments = [];
            if (props.functionArguments) {
              functionArguments = props.functionArguments.map((argument) => argument.encoded);
            }

            const operation = new Operation.InvokeContract()
              .contractId(props.contractId)
              .functionName(props.functionName)
              .functionArguments(functionArguments);

            let footprint = null;
            if (props.footprint?.type === 'manual') {
              footprint = Stellar.LedgerFootprint.fromXDR(props.footprint.string, 'base64');
            } else if (props.footprint?.type === 'automatic') {
              const transaction = await new Transaction()
                .sourceAccount(account)
                .operation(operation)
                .serializedFor(soroban.network);

              await transaction.signedBy(account);
              finalizedTransaction = transaction.finalized();

              const response = await soroban.network.executeRequest({
                method: 'simulateTransaction',
                parameters: {
                  transaction: finalizedTransaction
                }
              });

              if (response.footprint) {
                footprint = Stellar.LedgerFootprint.fromXDR(response.footprint, 'base64');
              }
            }
            
            const transaction = await new Transaction()
              .sourceAccount(account)
              .operation(operation.footprint(footprint))
              .serializedFor(soroban.network);

            await transaction.signedBy(account);
            finalizedTransaction = transaction.finalized();

            break;
          }
          default: {
            finalizedTransaction = null;
          }
        }
  
        if (!finalizedTransaction) {
          throw `Error: invalid Transaction definition.`;
        }
      } catch (error) {
        // console.log(error);
        return {
          method: 'sendTransaction',
          parameters: {
            transaction: null
          }
        };
      }

      return {
        method,
        parameters: {
          transaction: finalizedTransaction
        }
      };
    },
    [
      props.sourceAccount,
      props.hostFunction,
      props.code,
      props.codeHash,
      props.contractId,
      props.functionName,
      props.functionArguments,
      props.footprint
    ]
  );

  return request;
}

export {useRequest};