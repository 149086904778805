import {css, cx} from '@emotion/css';

import {Guide} from './components/Guide.jsx';
import {Markdown} from '../../components/Markdown.jsx';

const content = `
### Remote Tool

The [Remote](/tools/remote) tool is a browser interface to the Soroban RPC server.

You can query accounts and the ledger. You can browse network events. Most importantly, you can simulate, send, and check the status of transactions. Using the [Remote](/tools/remote) tool, you can do pretty much everything that you can with the Soroban CLI, without having to learn how to use the command a line or having to run your own local network.

#### Walkthrough

For a walkthrough of how to use it, please check out this YouTube video. In it, I go from Soroban Rust contract code, to covering all the RPC methods that are available, and how to use them with real life examples.

If you're interested in specific sections, you can jump straight to those using the video chapters.

- [Introduction][1]
- [Soroban contract code][2]
- [Deploying a Soroban contract][3]
- [Creating an instance of the Soroban contract][4]
- [Interacting with a Soroban contract][5]
- [Simulating a transaction][6]
- [getHealth method][7]
- [getAccount method][8]
- [getLedgerEntry method][9]
- [getEvents][10]
- [getEvents with the topics filter][11]
- [getEvents with pagination][12]
- [Design document, settings, and sample data][13]
- [Outro][14]

#### YouTube

[1]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=0s
[2]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=17s
[3]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=113s
[4]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=261s
[5]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=307s
[6]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=475s
[7]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=570s
[8]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=583s
[9]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=610s
[10]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=704s
[11]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=808s
[12]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=900s
[13]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=988s
[14]: https://www.youtube.com/watch?v=FPSWp9Bid9Q&t=1075s
`;

const styles = {
  youTube: css({
    width: '100%',
    height: 'calc(100vw/2.2)'
  })
};

function RemoteTool() {
  return (
    <Guide>
      <p className="fs-6 text-muted">January 9, 2023</p>
      <Markdown>
        {content}
      </Markdown>
      <iframe
        className={cx(styles.youTube)}
        src="https://www.youtube.com/embed/FPSWp9Bid9Q"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
      />
    </Guide>
  );
}

export {RemoteTool};