import {Method} from '../../Method';

import {useParameters} from './useParameters.jsx';
import {useRequest} from './useRequest.jsx';

function SimulateTransaction(props) {
  const [state, parameters] = useParameters();
  const request = useRequest('simulateTransaction', state);

  return (
    <Method
      name="simulateTransaction"
      parameters={parameters}
      description={
        <>
          <p>
            Before sending a transaction with a contract operation, you need to provide the ledger footprint for that transaction, <code>simulateTransaction</code> computes that for you.
          </p>
        </>
      }
      request={request}
    />
  );
}

export {SimulateTransaction};