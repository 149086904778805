import * as base64 from '../../../utilities/base64.js';
import Stellar from '../../../soroban/xdr/stellar.js';

function translate(xdr, buffer = {}) {
  if (xdr._attributes) {
    for (const attribute in xdr._attributes) {
      buffer[attribute] = translate(xdr._attributes[attribute]);
    }
  } else if (xdr.low !== undefined && xdr.high !== undefined && xdr.unsigned !== undefined) {
    // TODO: implement handling of big numbers.
    return xdr.low;
  } else if (xdr._switch !== undefined) {
    if (xdr._value) {
      if (xdr._value.length > 0) {
        buffer[xdr._arm] = [];
        for (const value of xdr._value) {
          buffer[xdr._arm].push(
            translate(value)
          )
        }
      } else {
        buffer[xdr._arm] = translate(xdr._value);
      }
    } else if (xdr._switch.name) {
      return xdr._switch.name;
    } else {
      return xdr._switch;
    }
  }

  return buffer;
}

class Result {
  static fromXDR(encoded) {
    const decoded = base64.decode(encoded);
    const serialized = Stellar.TransactionResult.fromXDR(decoded);

    const translated = translate(serialized);

    return translated;
  }
}

export {Result};