import {createContext, useContext, useState, useEffect, useCallback} from 'react';
import {useLocation} from 'react-router-dom';

const Context = createContext({});

function useNavigation() {
  return useContext(Context);
}

function withAbsolutePaths(structure, parent) {
  const modified = {
    ...structure
  };
  if (modified.path) {
    modified.path = `${parent}${modified.path}`;
  }
  if (modified.children) {
    modified.children = modified.children.map(
      (child) => withAbsolutePaths(child, parent)
    );
  }

  return modified;
}

function Provider(props) {
  const location = useLocation();
  const [isHidden, setHidden] = useState(true);
  const [subordinate, setSubordinate] = useState(null);
  
  const show = () => setHidden(false);
  const hide = () => setHidden(true);

  const showSubordinate = (nextSubordinate) => {
    if (!subordinate || subordinate.path !== nextSubordinate.path) {
      setSubordinate({
        path: nextSubordinate.path,
        structure: withAbsolutePaths(nextSubordinate.structure, nextSubordinate.path)
      });
    }
  };

  useEffect(() => {
    setHidden(true);
  }, [location]);

  return (
    <Context.Provider
      value={{
        isHidden,
        subordinate,
        showSubordinate,
        show,
        hide
      }}
      children={props.children}
    />
  );
}

export {Provider, useNavigation};