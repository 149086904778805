import {css, cx} from '@emotion/css';

import {material} from '../../../utilities/colors/material.js';

const styles = {
  invalidInput: css({
    borderRadius: '6px',
    boxShadow: `0px 0px 6px -2px ${material.red[500]}`,
  }),
  error: css({
    marginTop: '2px',
    color: material.red[700],
  })
};

function Wrapper(props) {
  if (props.hasFloatingLabel) {
    return (
      <div className="form-floating">
        {props.children}
        {props.label &&
          <label htmlFor={`input.${props.name}`}>
            {props.label}
          </label>
        }
      </div>
    );
  } else if (props.isGrouped) {
    return props.children;
  } else {
    return (
      <>
        {props.label &&
          <label className="form-label">
            {props.label}
          </label>
        }
        <div className={cx({[styles.invalidInput]: !!props.error})}>
          {props.children}
        </div>
        {props.error &&
          <div className={cx(styles.error)}>
            {props.error}
          </div>
        }
      </>
    );
  }
}

export {Wrapper};