import {useState, useContext} from 'react';
import {NavLink as Link} from 'react-router-dom';
import {css, cx} from '@emotion/css';

import structure from '../structure.json';

import {Network} from '../../../../packages/soroban/Network/Network.js';
import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';

import {Context} from '../Context.jsx';

import {Annotation} from '../../../../components/Annotation.jsx';
import {Parameters} from './Parameters.jsx';

import {material} from '../../../../utilities/colors';

const styles = {
  parameters: css({
    margin: '16px 0 16px 16px',
    padding: '4px 8px',
    backgroundColor: material.grey[100],
    borderRadius: '3px',
  }),
};

function Home(props) {
  const context = useContext(Context);
  const soroban = useSoroban();

  const [remoteUrl, setRemoteUrl] = useState(soroban.network.remoteUrl);
  const [networkPassphrase, setNetworkPassphrase] = useState(soroban.network.passphrase);
  const [parametersInUrl, setParametersInUrl] = useState(context.parametersInUrl);
  
  const wasModified = (
    soroban.network.remoteUrl !== new URL(remoteUrl).toString() ||
    soroban.network.passphrase !== networkPassphrase ||
    context.parametersInUrl !== parametersInUrl
  );
  
  const storeSettings = () => {
    const delta = {};
    
    if (soroban.network.remoteUrl !== remoteUrl) {
      try {
        delta.remoteUrl = new URL(remoteUrl).toString();
      } catch (error) {}
    }
    if (soroban.network.passphrase !== networkPassphrase) {
      delta.networkPassphrase = networkPassphrase;
    }

    if (Object.entries(delta).length > 0) {
      context.updateNetwork(delta);
    }

    if (context.parametersInUrl !== parametersInUrl) {
      context.onToggleParametersInUrl(parametersInUrl);
    }
  };

  return (
    <>
      <div className="col-12">
        <Annotation>
          This Soroban RPC tool has been created by following the <a href="https://docs.google.com/document/d/1TZUDgo_3zPz7TiPMMHVW_mtogjLyPL0plvzGMsxSz6A" target="_blank" rel="noreferrer">Soroban RPC Design Doc</a>.
        </Annotation>
      </div>
      <h1>
        Remote
      </h1>
      <p>
        This is a graphical tool for interacting with the <strong>Soroban RPC</strong>. The list of <em>methods</em> is not exhaustive at the moment, I'm still working on convering the entire API surface.
      </p>
      <div className="col-12 mb-2">
        <div className="form-floating">
          <input
            type="text"
            id="remote.url"
            className="form-control"
            placeholder="Soroban RPC URL"
            value={remoteUrl}
            onChange={(event) => setRemoteUrl(event.target.value)}
          />
          <label htmlFor="remote.url">
            Soroban RPC URL
          </label>
        </div>
        <div className="form-text">
          This defaults to a hosted <code>futurenet</code> URL.
        </div>
      </div>
      <div className="col-12 mb-2">
        <div className="form-floating">
          <select
            id="network.passphrase"
            className="form-select"
            value={networkPassphrase}
            onChange={(event) => setNetworkPassphrase(event.target.value)}
          >
            {Object.entries(Network.passphrases).map(([network, passphrase], index) => {
              return (
                <option key={index} value={passphrase}>
                  {passphrase}
                </option>
              );
            })}
          </select>
          <label htmlFor="network.passphrase">
            Network passphrase
          </label>
        </div>
        <div className="form-text">
          Make sure this matches the network passphrase of the Soroban RPC you have selected.
        </div>
      </div>
      <div className="col-12 mb-2">
        <div className="form-check form-switch">
          <input
            id="tool.remote.parametersInUrl"
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={parametersInUrl}
            onChange={(event) => setParametersInUrl(event.target.checked)}
          />
          <label className="form-check-label" htmlFor="tool.remote.parametersInUrl">
            Include RPC method parameters in URL
          </label>
        </div>
      </div>
      <div className="col-12 mb-5">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!wasModified}
          onClick={storeSettings}
        >
          store
        </button>
      </div>
      <h3>
        Methods
      </h3>
      <div className="col-12">
        <ul>
          {structure.children.map((child, index) => {
            return (
              <li key={index}>
                <Link to={`/tools/remote${child.path}`}>
                  {child.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <h5>
        Sample data
      </h5>
      <p>
        Some of these methods expect data before they can be called. If you simply want to see what each method returns, you can use these links with prepopulated data. This data only exists on <code>futurenet</code>.
      </p>
      <div className="col-12">
        <ul>
          {structure.children.map((child, index) => {
            if (child.parameters) {
              return child.parameters.map((parameters, index) => {
                const searchParams = new URLSearchParams(
                  parameters.pairs.map((pair) => [pair.name, pair.value])
                );
                const url = `/tools/remote${child.path}?${searchParams}`;

                return (
                  <li key={index}>
                    <Link to={url}>
                      {child.title}
                    </Link>
                    <Parameters {...parameters} />
                  </li>
                );
              });
            }
          })}
        </ul>
      </div>
    </>
  );
}

export {Home};