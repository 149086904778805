import Stellar from '../../../soroban/xdr/stellar.js';

class InstallContractCode {
  constructor() {
    this._code = null;
  }

  async serialized() {
    return new Stellar.Operation({
      body: Stellar.OperationBody.invokeHostFunction(
        new Stellar.InvokeHostFunctionOp({
          function: Stellar.HostFunction.hostFunctionTypeInstallContractCode(
            await this._code.serialized()
          ),
          footprint: new Stellar.LedgerFootprint({
            readOnly: [],
            readWrite: [
              Stellar.LedgerKey.contractCode(new Stellar.LedgerKeyContractCode({
                hash: await this._code.hash()
              }))
            ]
          })
        })
      )
    });
  }

  code(code) {
    this._code = code;
    return this;
  }
}

export {InstallContractCode};