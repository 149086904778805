import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';

import {Method} from '../Method';

function GetHealth(props) {
  const soroban = useSoroban();

  const request = {
    method: 'getHealth'
  };

  return (
    <Method
      name="getHealth"
      description="Run a health check on the Soroban JSON RPC."
      request={request}
    />
  );
}

export {GetHealth};