import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';

import {Method} from '../Method';

function GetLatestLedger(props) {
  const soroban = useSoroban();

  // const getLatestLedger = async () => {
  //   return await soroban.network.getLatestLedger();
  // };

  const request = {
    method: 'getLatestLedger'
  };

  return (
    <Method
      name="getLatestLedger"
      isWIP={true}
      description="Find latest ledger."
      request={request}
    />
  );
}

export {GetLatestLedger};