import Stellar from '../xdr/stellar.js';

class Code {
  constructor(source) {
    this.source = source;
  }

  async serialized() {
    return new Stellar.InstallContractCodeArgs({
      code: this.source
    });
  }

  async hash() {
    const serialized = await this.serialized();
    return new Uint8Array(
      await crypto.subtle.digest('SHA-256', serialized.toXDR())
    );
  }
}

export {Code};