import {cx} from '@emotion/css';

function Button(props) {
  return (
    <button
      className={cx({
        'btn': true,
        'btn-primary': props.type === 'primary',
        'btn-secondary': props.type === 'secondary',
        'btn-outline-secondary': props.type === 'tertiary'
      })}
      style={{marginRight: '8px'}}
      onClick={props.onClick}
    >
      {props.isWaiting &&
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      }
      {!props.isWaiting &&
        <span>{props.title}</span>
      }
    </button>
  );
}

export {Button};