const constants = {
  on: 1,
  off: 0
};

class Grid {
  static fromInteger(integer, length) {
    const cells = integer
      .toString(2)
      .padStart((length * length), '0')
      .split('')
      .map((cell) => Number.parseInt(cell));
    return new Grid(length, cells.slice(0, 25));
  }
  constructor(length, cells = null) {
    this.length = length;
    this.cells = cells || Array.from(Array(this.length * this.length), () => 0);
  }
  activate(cell) {
    return this.update(cell, constants.on);
  }
  deactivate(cell) {
    return this.update(cell, constants.off);
  }
  toggle(cell) {
    return this.update(cell, this.cells[cell] === constants.on ? constants.off : constants.on);
  }
  update(cell, nextState) {
    const cells = this.cells.map(
      (currentState, index) => index === cell ? nextState : currentState
    );
    return new Grid(this.length, cells);
  }
  encoded() {
    const binaryString = '0b' + this.cells.join('');

    return Number.parseInt(BigInt(binaryString));
  }
}

export {Grid};