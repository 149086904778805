import {useState, useContext} from 'react';

import {Context} from '../Context.jsx';

import {Method} from '../Method';

import {Text} from '../../../../components/inputs';

function RequestAirdrop(props) {
  const context = useContext(Context);

  const [address, setAddress] = useState(context.parameters.address || null);
  
  const request = {
    method: 'requestAirdrop',
    parameters: {
      address
    }
  };

  const parameters = [
    <Text
      name="address"
      placeholder="Address"
      description={
        <>
          Stellar account address formatted as <code>G...</code>, same as the Public Key.
        </>
      }
      value={address}
      onChange={(nextAddress) => setAddress(nextAddress)}
    />
  ];

  return (
    <Method
      name="requestAirdrop"
      isWIP={true}
      description="Request lumens (native Stellar blockchain currency) for an account."
      parameters={parameters}
      request={request}
    />
  );
}

export {RequestAirdrop};