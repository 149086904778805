import {useContext, useReducer, useMemo} from 'react';

import * as base64 from '../../../../packages/utilities/base64.js';
import * as hex from '../../../../packages/utilities/hex.js';

import Stellar from '../../../../packages/soroban/xdr/stellar.js';
import {Keys} from '../../../../packages/stellar/Keys.js';

import {Context} from '../Context.jsx';

import {Method} from '../Method';

import {Options, Text, SorobanValue} from '../../../../components/inputs';

/*
xdr.union("LedgerKey", {
  switchOn: xdr.lookup("LedgerEntryType"),
  switchName: "type",
  switches: [
    ["account", "account"],
    ["trustline", "trustLine"],
    ["offer", "offer"],
    ["data", "data"],
    ["claimableBalance", "claimableBalance"],
    ["liquidityPool", "liquidityPool"],
    ["contractData", "contractData"],
    ["contractCode", "contractCode"],
    ["configSetting", "configSetting"],
  ],
  arms: {
    account: xdr.lookup("LedgerKeyAccount"),
    trustLine: xdr.lookup("LedgerKeyTrustLine"),
    offer: xdr.lookup("LedgerKeyOffer"),
    data: xdr.lookup("LedgerKeyData"),
    claimableBalance: xdr.lookup("LedgerKeyClaimableBalance"),
    liquidityPool: xdr.lookup("LedgerKeyLiquidityPool"),
    contractData: xdr.lookup("LedgerKeyContractData"),
    contractCode: xdr.lookup("LedgerKeyContractCode"),
    configSetting: xdr.lookup("LedgerKeyConfigSetting"),
  },
});
*/

function reducer(state, action) {
  switch (action.type) {
    case 'setType': {
      return {
        ...state,
        type: action.payload,
        contractCodeHash: null,
        contractId: null,
        contractDataKey: null
      };
    }
    case 'setContractCodeHash': {
      return {
        ...state,
        contractCodeHash: action.payload,
      };
    }
    case 'setContractId': {
      return {
        ...state,
        contractId: action.payload
      };
    }
    case 'setAccountAddress': {
      return {
        ...state,
        accountAddress: action.payload
      };
    }
    case 'setContractDataKey': {
      return {
        ...state,
        contractDataKey: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

// console.log(Stellar);
function GetLedgerEntry(props) {
  const context = useContext(Context);

  const [state, dispatch] = useReducer(reducer, {
    type: context.parameters.type || null,
    contractCodeHash: context.parameters.contractCodeHash || null,
    contractId: context.parameters.contractId || null,
    accountAddress: context.parameters.address || null,
    contractDataKey: null
  });

  const parameters = [
    <Options
      name="type"
      placeholder="Type"
      description={
        <>
          There are <code>9</code> different <code>LedgerKey</code> types, but this tool only supports <code>2</code>.
        </>
      }
      options={[
        {
          value: 'contractData',
          title: 'Contract Data'
        },
        {
          value: 'contractCode',
          title: 'Contract Code'
        },
        {
          value: 'account',
          title: 'Account'
        }
      ]}
      value={state.type}
      onChange={(nextType) => dispatch({
        type: 'setType',
        payload: nextType
      })}
    />
  ];

  if (state.type === 'contractData') {
    parameters.push(
      <Text
        name="contractId"
        placeholder="Contract ID"
        description="Contract ID, encoded in hexadecimal."
        value={state.contractId}
        onChange={(nextContractId) => dispatch({
          type: 'setContractId',
          payload: nextContractId
        })}
      />,
      <SorobanValue
        name="contractDataKey"
        placeholder="Data key"
        description={
          <>
            Use the same value as when calling <code>env.storage().set()</code>, as per the <a href="https://docs.rs/soroban-sdk/0.4.2/soroban_sdk/storage/struct.Storage.html#method.set" target="_blank" rel="noreferrer">docs</a>.
          </>
        }
        value={state.contractDataKey}
        onChange={(nextValue) => dispatch({
          type: 'setContractDataKey',
          payload: nextValue
        })}
      />
    );
  } else if (state.type === 'contractCode') {
    parameters.push(
      <Text
        name="contractCodeHash"
        placeholder="Contract code hash"
        description="Hash of the contract code binary, encoded in Base 64."
        value={state.contractCodeHash}
        onChange={(nextContractCodeHash) => dispatch({
          type: 'setContractCodeHash',
          payload: nextContractCodeHash
        })}
      />
    );
  } else if (state.type === 'account') {
    parameters.push(
      <Text
      name="address"
      placeholder="Address"
      description={
        <>
          Stellar account address formatted as <code>G...</code>, same as the Public Key.
        </>
      }
      value={state.accountAddress}
      onChange={(nextAddress) => dispatch({
        type: 'setAccountAddress',
        payload: nextAddress
      })}
    />
    );
  }

  const request = useMemo(
    () => {
      let key = null;

      try {
        switch (state.type) {
          case 'contractData': {
            if (state.contractDataKey.encoded) {
              key = Stellar.LedgerKey.contractData(
                new Stellar.LedgerKeyContractData({
                  contractId: hex.decode(state.contractId),
                  key: state.contractDataKey.encoded
                })
              );
            }
            break;
          }
          case 'contractCode': {
            key = Stellar.LedgerKey.contractCode(
              new Stellar.LedgerKeyContractCode({
                hash: hex.decode(state.contractCodeHash)
              })
            );
            break;
          }
          case 'account': {
            const keys = Keys.fromString(state.accountAddress);
            key = Stellar.LedgerKey.account(
              new Stellar.LedgerKeyAccount({
                accountId: Stellar.AccountId.publicKeyTypeEd25519(keys.public)
              })
            );
            break;
          }
          default: {
            key = null;
          }
        }
  
        if (!key) {
          throw `Error: invalid LedgerKey definition.`;
        }
      } catch (error) {
        return {
          method: 'getLedgerEntry',
          parameters: {
            key: null
          }
        };
      }

      return {
        method: 'getLedgerEntry',
        parameters: {
          key: base64.encode(key.toXDR())
        }
      };
    },
    [
      state.type,
      state.contractDataKey,
      state.contractId,
      state.contractCodeHash,
      state.accountAddress
    ]
  );

  return (
    <Method
      name="getLedgerEntry"
      parameters={parameters}
      description="Read ledger entries directly. Inspect contract state, read contract's code, etc."
      request={request}
    />
  );
}

export {GetLedgerEntry};