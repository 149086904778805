function Documentation(props) {
  return (
    <div className="container-fluid">
      <div className="row g-2 mb-5">
        {props.children}
      </div>
    </div>
  );
}

export {Documentation};