import {css, cx} from '@emotion/css';

import {Wrapper} from './components/Wrapper.jsx';

import {material} from '../../utilities/colors';

const styles = {
  title: css({}),
  separator: css({
    margin: '0 8px 0 8px',
    color: material.grey[500],
  }),
  description: css({})
};

function Settings(props) {
  const onSet = (name, isSet) => {
    props.onChange({
      ...props.value,
      [name]: isSet
    });
  };

  return (
    <Wrapper label={props.placeholder}>
      {props.settings.map((setting, index) => {
        return (
          <div key={index} className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={props.value[setting.name] === true}
              onChange={(event) => onSet(setting.name, event.target.checked)}
            />
            <label className="form-check-label" htmlFor="test">
              <span className={cx(styles.title)}>
                {setting.title}
              </span>
              <span className={cx(styles.description)}>
                <span className={cx(styles.separator)}>-</span>
                {setting.description}
              </span>
            </label>
          </div>
        );
      })}
    </Wrapper>
  );
}

export {Settings};