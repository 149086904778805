import * as base64 from '../../utilities/base64.js';
import Stellar from '../../soroban/xdr/stellar.js';

/*
struct Transaction
{
    // account used to run the transaction
    MuxedAccount sourceAccount;

    // the fee the sourceAccount will pay
    uint32 fee;

    // sequence number to consume in the account
    SequenceNumber seqNum;

    // validity conditions
    Preconditions cond;

    Memo memo;

    Operation operations<MAX_OPS_PER_TX>;

    // reserved for future use
    union switch (int v)
    {
    case 0:
        void;
    }
    ext;
};
*/

class Transaction {
  static get minimumFee() {
    return 100;
  }

  constructor() {
    this._sourceAccount = null;
    this._fee = Transaction.minimumFee;
    this._sequenceNumber = null;
    this._preconditions = null;
    this._memo = null;
    this._operations = [];
    this._extension = null;

    this.signatures = [];

    this.hash = null;
    this.serialized = null;
    this.serializedSignaturePayload = null;
  }

  get isValid() {
    // These fields are required.
    return (
      this._sourceAccount !== null &&
      this._fee !== null &&
      this._operations.length > 0
    );
  }

  sourceAccount(account) {
    this._sourceAccount = account;
    return this;
  }
  fee(amount) {
    this._fee = amount;
    return this;
  }
  preconditions(preconditions) {
    this._preconditions = preconditions;
    return this;
  }
  operation(operation) {
    this._operations.push(operation);
    return this;
  }

  async serializedFor(network) {
    if (!this._sourceAccount) {
      throw `Error: source account not defined for this transactions.`;
    }

    await network.hydrate();
    await this._sourceAccount.hydrateOn(network);
    this._sequenceNumber = BigInt(this._sourceAccount.sequenceNumber) + 1n;

    this.serialized = new Stellar.Transaction({
      sourceAccount: this._sourceAccount.serialized,
      fee: this._fee,
      seqNum: Stellar.SequenceNumber.fromString(this._sequenceNumber.toString()),
      cond: this._preconditions || Stellar.Preconditions.precondNone(),
      memo: Stellar.Memo.memoNone(),
      operations: await Promise.all(
        this._operations.map((operation) => operation.serialized())
      ),
      ext: new Stellar.TransactionExt(0)
    });

    this.serializedSignaturePayload = new Stellar.TransactionSignaturePayload({
      networkId: network.id,
      taggedTransaction: Stellar.TransactionSignaturePayloadTaggedTransaction.envelopeTypeTx(
        this.serialized
      )
    });

    this.hash = new Uint8Array(
      await crypto.subtle.digest('SHA-256', this.serializedSignaturePayload.toXDR())
    );

    return this;
  }

  async signedBy(account) {
    this.signatures.push(
      account.signDecorated(this.hash)
    );
    
    return this;
  }

  serializedEnvelope() {
    return Stellar.TransactionEnvelope.envelopeTypeTx(
      new Stellar.TransactionV1Envelope({
        tx: this.serialized,
        signatures: this.signatures
      })
    );
  }
  finalized() {
    return base64.encode(
      new Uint8Array(
        this.serializedEnvelope().toXDR()
      )
    );
  }
}

export {Transaction};