function Parameter(props) {
  let description = null;

  if (props.description) {
    description = props.description;
  } else if (props.children?.props?.description) {
    description = props.children?.props?.description;
  }

  return (
    <div className="col-12 mb-2">
      {props.children}
      {description &&
        <div className="form-text">
          {description}
        </div>
      }
    </div>
  );
}

export {Parameter};