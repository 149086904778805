import {useContext} from 'react';
import {css, cx} from '@emotion/css';
import {Routes, Route, useNavigate} from 'react-router-dom';

import {GameProvider, GameContext} from './Context.jsx';

import {Navigation} from './components/Navigation.jsx';
import {Button} from './Button.jsx';

import * as pages from './pages';

const styles = {
  game: css({
    padding: '0 16px 16px 16px',
  }),
};

function Game(props) {
  const gameContext = useContext(GameContext);
  const navigate = useNavigate();

  const onLogin = () => {
    const secretKey = window.prompt('Please enter your secret key.');
    gameContext.login(secretKey);
    navigate('/apps/game/canvas');
  };

  return (
    <div className={cx(styles.game)}>
      <Navigation />
      <Routes>
        <Route path="/" element={<p>Please login using your FutureNet secret key.</p>} />
        <Route path="/canvas" element={<pages.Canvas />} />
        <Route path="/library" element={<pages.Library />} />
      </Routes>
      {!gameContext.account &&
        <div>
          <Button
            type="primary"
            title="login"
            onClick={onLogin}
          />
        </div>
      }
    </div>
  );
}

function WrapperGame(props) {
  return (
    <GameProvider>
      <Game />
    </GameProvider>
  );
}

export {WrapperGame as Game};