import {NavLink as Link} from 'react-router-dom';

import {Guide} from './components/Guide.jsx';
import {Markdown} from '../../components/Markdown.jsx';
import {Annotation} from '../../components/Annotation.jsx';

const content = `
### Testing

Testing any kind of software allows us to catch bugs early. This is even more important when writing smart contracts.

When building a JavaScript app we have a number of testing framework options, such as Jest and others. Since Soroban is built on Rust we will use Rust testing frameworks. But first, basic setup, let's create a new \`lib.rs\` file in our \`/src/\` folder.

#### Setup

~~~rust:lib.rs
pub struct Calculator;

impl Calculator {
    pub fn add() -> i64 {
        42
    }
}
~~~

OK, so we start with pure Rust on this one, and layer more complexity and then Soroban on top towards the end. Our initial code creates a \`Calculator\` structure and implements the \`add()\` function. This function returns a single \`i64\` or unsigned 64-bit integer.

The strange part of the syntax is the random looking \`42\` in the function body. You might be familiar with the implicit return of \`undefined\` in JavaScript functions if no \`return\` statement is provided. Well, Rust also uses implicit returns - it takes the last expression and returns it (mind the missing semicolon \`;\`). If the lest expression ends with \`;\` the function then returns \`()\`, which is the [\`unit\` type][2], as per the [docs][1].

With that out of the way, let's go test this thing!

#### First Test

~~~rust:lib.rs:9
#[cfg(test)]
mod tests {
    #[test]
    fn add() {
        let sum = super::Calculator::add();
        assert_eq!(sum, 42);
    }
}
~~~

Let's begin with \`#[cfg(test)]\`, which is Rust annotation that instructs the Rust compiler to only compile and run the following code when you're testing. Your binary won't include test code.

Next we declare a \`mod tests\` module that will contain our tests. Each individual test has to be annotated with \`#[test]\`.

Lastly, we declare our test \`test_add()\` and include a single assertion macro of equality \`assert_eq!\`. Since \`Calculator\` is declared outside of the \`tests\` module, we need to use [\`super\`][3] to access stuff defined in the parent scope.

#### Running Tests

~~~sh
cargo test
~~~

You run tests with the \`cargo test\` command. Cargo goes through each file in the \`src/\` folder and looks for code annotated with \`#[cfg(test)]\` to execute. After running this test your terminal should contain the following.

~~~
running 1 test
test tests::add ... ok

test result: ok. 1 passed; 0 failed; 0 ignored; 0 measured; 0 filtered out; finished in 0.00s
~~~

#### Second Test

Our \`Calculator\` is boring, it always returns \`42\` when calling \`add()\`, let's do better.

~~~rust:lib.rs
pub struct Calculator;

impl Calculator {
    pub fn add(left: i64, right: i64) -> i64 {
        left + right
    }
}

#[cfg(test)]
mod tests {
    #[test]
    fn add() {
        let sum = super::Calculator::add(1, 2);
        assert_eq!(sum, 42);
    }
}
~~~

The following implementation takes two integers and returns their sum. Without changing the assertion, let's run the test again and see what we get.

~~~
running 1 test
test tests::add ... FAILED

failures:

---- tests::add stdout ----
thread 'tests::add' panicked at 'assertion failed: \`(left == right)\`
  left: \`3\`,
 right: \`42\`', src/lib.rs:14:9
note: run with \`RUST_BACKTRACE=1\` environment variable to display a backtrace


failures:
    tests::add

test result: FAILED. 0 passed; 1 failed; 0 ignored; 0 measured; 0 filtered out; finished in 0.00s
~~~

Naturally, adding \`1\` and \`2\` does not equal \`42\`, so we get a \`panic\` and the assertion fails the test. Let's say we're building software using the [Test Driven Development (TDD)][4] principles, it's normal to first create a failing test before we fully implement a function. In this case, we can annotate the test with \`#[should_panic]\`.

~~~rust:lib.rs:11
#[test]
#[should_panic]
fn add() {
    let sum = super::Calculator::add(1, 2);
    assert_eq!(sum, 42);
}
~~~

If we run the test now, we'll get this result.

~~~
running 1 test
test tests::add - should panic ... ok

test result: ok. 1 passed; 0 failed; 0 ignored; 0 measured; 0 filtered out; finished in 0.00s
~~~

If the test doesn't fail, then we'll get an failure.

~~~
running 1 test
test tests::add - should panic ... FAILED

failures:

---- tests::add stdout ----
note: test did not panic as expected

failures:
    tests::add

test result: FAILED. 0 passed; 1 failed; 0 ignored; 0 measured; 0 filtered out; finished in 0.00s
~~~

### Testing Soroban Contracts

~~~rust:lib.rs
use soroban_sdk::{contractimpl};

pub struct CalculatorContract;

#[contractimpl]
impl CalculatorContract {
    pub fn add(left: i64, right: i64) -> i64 {
        left + right
    }
}

#[cfg(test)]
mod tests {
    use soroban_sdk::{Env};

    #[test]
    fn add() {
        let env = Env::default();
        let contract_id = env.register_contract(None, super::CalculatorContract);
        let client = super::CalculatorContractClient::new(&env, &contract_id);

        let sum = client.add(&1, &2);
        assert_eq!(sum, 3);
    }
}
~~~

I refactored the \`Calculator\` structure into a Soroban contract simply by annotating it with \`#[contractimpl]\`. However, to test said contract now requires more setup in the test function.

#### Soroban Environment

Every Soroban contract will run inside the Soroban environment, \`Env\`, which we import from the sdk.

~~~rust::18
let env = Env::default();
~~~

\`default()\` works together with \`Default\`, which is a [Rust trait][5] for providing default values. Since \`Env\` is a Rust \`struct\` with fields and sub-fields, by calling \`Env::default()\` we are provided with a fully initialized environment (and if we're calling it from a test, it knows to initialize specifically for that).

If you're curious, here's a trace towards the actual return value of the previous function, hint - there are layers and layers of hidden complexity 😉.

1. [\`Env::default\`][6]
2. [\`Env::default_with_testutils\`][7]
3. [\`Host::with_storage_and_budget\`][8]
4. [\`HostImpl\`][9]

With that out of the way, we can register our contract with the Soroban environment to get an ID assigned to it.

~~~rust::19
let contract_id = env.register_contract(None, super::CalculatorContract);
~~~

By passing [\`None\`][10] into \`register_contract()\` we're allowing the environment to pick a random number ID for the contract.

#### Interacting with the Contract

You can't interact with the \`CalculatorContract\` directly if you want to interact with the Soroban environment, meaning the blockchain. To use the Soroban contract \`CalculatorContract\` you need a client for it which references a specific Soroban environment, and a specific contract using the contract ID.

~~~rust::20
let client = super::CalculatorContractClient::new(&env, &contract_id);
~~~

Cool, so now we have a \`client\` which allows us to call our \`pub add()\` function and assert a return value. Oh, and we're only able to call functions that are declared as public.

~~~rust::22
let sum = client.add(&1, &2);
assert_eq!(sum, 3);
~~~

The only difference here is that we pass references to \`&1\` and \`&2\` instead of just integer values. This is done by the Soroban \`contractimpl\` macro.

#### Testing a Soroban Contract

With all of the environment and contract client setup done, we test Soroban contracts the same as anything else in Rust, using \`cargo test\`.

~~~sh
cargo test
~~~

The previous test should yield the following result.

~~~
running 1 test
test tests::add ... ok

test result: ok. 1 passed; 0 failed; 0 ignored; 0 measured; 0 filtered out; finished in 0.00s
~~~

[1]: https://doc.rust-lang.org/stable/rust-by-example/expression.html
[2]: https://doc.rust-lang.org/std/primitive.unit.html
[3]: https://doc.rust-lang.org/rust-by-example/mod/super.html
[4]: https://en.wikipedia.org/wiki/Test-driven_development
[5]: https://doc.rust-lang.org/std/default/trait.Default.html#how-can-i-implement-default
[6]: https://github.com/stellar/rs-soroban-sdk/blob/main/soroban-sdk/src/env.rs#L79
[7]: https://github.com/stellar/rs-soroban-sdk/blob/main/soroban-sdk/src/env.rs#L289
[8]: https://github.com/stellar/rs-soroban-env/blob/main/soroban-env-host/src/host.rs#L198
[9]: https://github.com/stellar/rs-soroban-env/blob/main/soroban-env-host/src/host.rs#L131
[10]: https://doc.rust-lang.org/std/option/
`;



function Testing() {
  return (
    <Guide>
      <Annotation>
      This guide was created by following the <a href="https://soroban.stellar.org/docs/tutorials/testing" target="_blank" rel="noreferrer">Testing</a> tutorial from the official Soroban documentation.
      </Annotation>
      <p className="fs-6 text-muted">November 22, 2022</p>
      <Markdown>
        {content}
      </Markdown>
      
      <div className="card">
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">Next guide</h6>
          <Link className="btn btn-primary" to="/guides/building">Building</Link>
        </div>
      </div>
    </Guide>
  );
}

export {Testing};