import {useLayoutEffect, createElement} from 'react';
import {css, cx} from '@emotion/css';
import {useLocation, Route, Routes} from 'react-router-dom';

import {SorobanProvider} from './packages/soroban/Soroban.jsx';

import {Icons} from './components/Icons';
import {Header} from './components/Header.jsx';
import {Footer} from './components/Footer.jsx';
import {Navigation, Provider as NavigationProvider, useNavigation} from './components/Navigation';

import {Home} from './pages/Home.jsx';
import {Funding} from './pages/dao/Funding.jsx';
import {RemoteTool} from './pages/tools/Remote';
import {ContractTool} from './pages/tools/Contract.jsx';
import guides from './pages/guides';

import * as documentation from './pages/documentation';
import * as apps from './pages/apps';

import {material} from './utilities/colors';
import * as device from './utilities/device.js';

const styles = {
  
  app: css({
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    minHeight: '100%',
    maxWidth: '1400px',
    margin: 'auto',
    padding: '64px 32px',
    [device.media.mobile]: {
      padding: '0 0 16px 0',
    }
  }),
  main: css({
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    [device.media.mobile]: {
      flexDirection: 'column',
    }
  }),
  article: css({
    flex: '1',
  }),
  articleIsHidden: css({
    display: 'none',
    [device.media.desktop]: {
      display: 'block',
    },
    [device.media.tablet]: {
      display: 'block',
    }
  }),
};

function App(props) {
  const location = useLocation();
  const navigation = useNavigation();

  useLayoutEffect(() => {
    fetch(`/navigate-to?path=${window.btoa(location.pathname)}`);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={cx(styles.app)}>
      <Header />

      <main className={cx(styles.main)}>
        <Navigation />
        <article
          className={cx({
            [styles.article]: true,
            [styles.articleIsHidden]: !navigation.isHidden
          })}
        >
          <Routes on>
            <Route
              element={<Home />}
              path="/"
            />
            {guides.map((guide, index) => {
              if (guide.component) {
                return (
                  <Route
                    key={index}
                    element={createElement(guide.component)}
                    path={guide.path}
                  />
                );
              } else {
                return null;
              }
            })}
            <Route path="/tools/remote/*" element={<RemoteTool />} />
            <Route path="/tools/contract" element={<ContractTool />} />
            <Route path="/dao/funding" element={<Funding />} />
            <Route path="/documentation/components/soroban-value" element={<documentation.components.SorobanValue />} />
            <Route path="/documentation/components/input-array" element={<documentation.components.InputArray />} />
            <Route path="/apps/game/*" element={<apps.Game />} />
          </Routes>
        </article>
      </main>

      <Footer />
    </div>
  );
}

function WrappedApp(props) {
  return (
    <SorobanProvider
      networkPassphrase="Test SDF Future Network ; October 2022"
      remoteUrl="https://futurenet.sorobandev.com/soroban/rpc"
    >
      <NavigationProvider>
        <Icons />
        <App {...props} />
      </NavigationProvider>
    </SorobanProvider>
  );
}

export {WrappedApp as default};
