import {useReducer, useContext, createContext, useEffect} from 'react';

import {Network} from './Network/Network.js';

const Context = createContext({});

function reducer(state, action) {
  switch(action.type) {
    case 'setNetwork': {
      return {
        ...state,
        network: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

function Provider(props) {
  const [state, dispatch] = useReducer(reducer, {
    network: new Network(props.networkPassphrase, props.remoteUrl)
  });

  useEffect(() => {
    if (
      state.network.passphrase !== props.networkPassphrase ||
      state.network.remoteUrl !== props.remoteUrl
    ) {
      const nextNetwork = new Network(
        props.networkPassphrase,
        props.remoteUrl
      );
      dispatch({
        type: 'setNetwork',
        payload: nextNetwork
      });
    }
  }, [state.network, props.networkPassphrase, props.remoteUrl]);

  return (
    <Context.Provider
      value={{
        network: state.network
      }}
      children={props.children}
    />
  );
}

function useSoroban() {
  const context = useContext(Context);
  return context;
}

export {
  Context,
  Provider as SorobanProvider,
  useSoroban
};