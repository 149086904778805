import {useEffect, useContext, useReducer} from 'react';

import Stellar from '../../../../packages/soroban/xdr/stellar.js';

import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';

import {Context} from '../Context.jsx';

import {Method} from '../Method';

import * as inputs from '../../../../components/inputs';

function reducer(state, action) {
  switch (action.type) {
    case 'setStartLedger': {
      return {
        ...state,
        startLedger: action.payload
      };
    }
    case 'setEndLedger': {
      return {
        ...state,
        endLedger: action.payload
      };
    }
    case 'updateFilterSettings': {
      return {
        ...state,
        filterSettings: action.payload,
        typeFilter: action.payload.type ? state.typeFilter : null,
        contractIdsFilter: action.payload.contractIds ? state.contractIdsFilter : null,
        topicsFilter: action.payload.topics ? state.topicsFilter : null
      };
    }
    case 'setTypeFilter': {
      return {
        ...state,
        typeFilter: action.payload
      };
    }
    case 'setContractIdsFilter': {
      return {
        ...state,
        contractIdsFilter: action.payload
      };
    }
    case 'setTopicsFilter': {
      return {
        ...state,
        topicsFilter: action.payload
      };
    }
    case 'setCursor': {
      return {
        ...state,
        cursor: action.payload
      };
    }
    case 'setLimit': {
      const limit = action.payload.length > 0 ? Number.parseInt(action.payload) : null;

      return {
        ...state,
        limit
      };
    }
    default: {
      return state;
    }
  }
}

function GetEvents(props) {
  const context = useContext(Context);
  const soroban = useSoroban();
  
  const [state, dispatch] = useReducer(reducer, {
    startLedger: context.parameters.startLedger || null,
    endLedger: context.parameters.endLedger || null,
    filterSettings: {
      type: !!context.parameters.typeFilter,
      contractIds: !!context.parameters.contractIdsFilter,
      // topics: true
    },
    typeFilter: context.parameters.typeFilter || null,
    contractIdsFilter: context.parameters.contractIdsFilter || null,
    topicsFilter: [
      // {type: 'wildcard'},
      // {type: 'wildcard'},
      // {type: 'wildcard'},
      // {type: 'symbol', string: 'TOGGLE'}
    ],
    cursor: context.parameters.cursor || null,
    limit: context.parameters.limit !== undefined ? Number.parseInt(context.parameters.limit) : null
  });

  const fetchLatestLedger = async () => {
    try {
      const horizonUrl = soroban.network.remoteUrl.replace('/soroban/rpc', '');
    
      const response = await fetch(`${horizonUrl}/ledgers?order=desc`);
      const responseJson = await response.json();

      const latestLedger = responseJson._embedded.records[0];

      dispatch({
        type: 'setStartLedger',
        payload: `${Math.max(latestLedger.sequence - 4000, 0)}`
      });
      dispatch({
        type: 'setEndLedger',
        payload: `${latestLedger.sequence}`
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.startLedger === null && state.endLedger === null) {
      fetchLatestLedger();
    }
  }, [state.startLedger, state.endLedger]);

  // Filters

  const filter = {};
  
  if (state.typeFilter) {
    filter.type = state.typeFilter;
  }
  if (state.contractIdsFilter) {
    filter.contractIds = state.contractIdsFilter
      .split(',')
      .map((contractId) => contractId.trim())
      .filter((contractId) => contractId.length > 0);
  }
  if (state.topicsFilter) {
    try {
      const topics = state.topicsFilter
        .filter((filter) => filter !== null)
        .map((filter) => {
          switch (filter.type) {
            case 'wildcard': return '*';
            case 'symbol': return Stellar.ScVal.scvSymbol(filter.string || '');
            default: throw 'Error: unknown filter type.';
          }
        })
        .map((value) => typeof value === 'string' ? value : value.toXDR('base64'));

      if (topics.length > 0) {
        filter.topics = [topics];
      }
    } catch (error) {
      console.log(error);
    }
  }

  const filters = [];
  if (Object.getOwnPropertyNames(filter).length > 0) {
    filters.push(filter);
  }

  // Pagination.

  const pagination = {};
  if (state.cursor) {
    pagination.cursor = state.cursor;
  }
  if (state.limit !== undefined && state.limit !== null) {
    pagination.limit = state.limit
  }

  const request = {
    method: 'getEvents',
    parameters: {
      startLedger: state.startLedger,
      endLedger: state.endLedger,
      filters: filters.length > 0 ? filters : undefined,
      pagination: Object.getOwnPropertyNames(pagination).length > 0 ? pagination : undefined
    }
  };

  const parameters = [
    <inputs.Text
      name="startLedger"
      placeholder="Start Ledger"
      description="Ledger sequence number."
      value={state.startLedger}
      onChange={(nextLedger) => dispatch({
        type: 'setStartLedger',
        payload: nextLedger
      })}
    />,
    <inputs.Text
      name="endLedger"
      placeholder="End Ledger"
      description="Ledger sequence number."
      value={state.endLedger}
      onChange={(nextLedger) => dispatch({
        type: 'setEndLedger',
        payload: nextLedger
      })}
    />,
    <inputs.Text
      name="cursor"
      placeholder="Pagination cursor"
      value={state.cursor}
      onChange={(nextCursor) => dispatch({
        type: 'setCursor',
        payload: nextCursor
      })}
    />,
    <inputs.Text
      name="limit"
      placeholder="Pagination limit"
      value={state.limit}
      onChange={(nextLimit) => dispatch({
        type: 'setLimit',
        payload: nextLimit
      })}
    />,
    <inputs.Settings
      name="filters"
      placeholder="Filters"
      description="Select how you want to filter which events get returned. Default is no filters."
      settings={[
        {
          name: 'type',
          title: 'Type',
          description: 'system events or contract events only.'
        },
        {
          name: 'contractIds',
          title: 'Contract IDs',
          description: 'events from a specific contract.'
        },
        {
          name: 'topics',
          title: 'Topics',
          description: 'events that match specific topics.'
        }
      ]}
      value={state.filterSettings}
      onChange={(nextSettings) => dispatch({
        type: 'updateFilterSettings',
        payload: nextSettings
      })}
    />
  ];

  if (state.filterSettings.type) {
    parameters.push(
      <inputs.Options
        name="typeFilter"
        placeholder="Type"
        options={[
          {
            value: 'system',
            title: 'System'
          },
          {
            value: 'contract',
            title: 'Contract'
          }
        ]}
        value={state.typeFilter}
        onChange={(nextFilter) => dispatch({
          type: 'setTypeFilter',
          payload: nextFilter
        })}
      />
    );
  }
  if (state.filterSettings.contractIds) {
    parameters.push(
      <inputs.Text
        name="contractIdsFilter"
        placeholder="Contract IDs"
        description="Up to 5 contract IDs, encoded in hex, comma separated."
        value={state.contractIdsFilter}
        onChange={(nextFilter) => dispatch({
          type: 'setContractIdsFilter',
          payload: nextFilter
        })}
      />
    );
  }
  if (state.filterSettings.topics) {
    parameters.push(
      <inputs.InputArray
        name="topicsFilter"
        label="Topic"
        description={
          <>Up to <code>4</code> topic segments.</>
        }
        input={{
          type: inputs.TypedText,
          props: {
            types: [
              {
                name: 'wildcard',
                title: 'Wildcard',
                isIdentity: true
              },
              {
                name: 'symbol',
                title: 'Symbol'
              }
            ]
          }
        }}
        maxLength={4}
        value={state.topicsFilter}
        onChange={(nextValue) => dispatch({
          type: 'setTopicsFilter',
          payload: nextValue
        })}
      />
    );
  }

  return (
    <Method
      name="getEvents"
      parameters={parameters}
      description={
        <>
          <p>
            Fetch events emitted by Soroban contracts and/or the system within a given time span. The Soroban RPC only retains events from the last 24-hour periods.
          </p>
          <p>
            If no values provided for the start and end ledgers, those values are populated with the latest ledger as the <em>End ledger</em> and latest ledger minus <code>4000</code> for the <em>Start ledger</em>.
          </p>
        </>
      }
      request={request}
    />
  );
}

export {GetEvents};