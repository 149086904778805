import {useContext, useReducer} from 'react';

import {Context} from '../../Context.jsx';

import {Text, Options, File, InputArray, TypedText, SorobanValue} from '../../../../../components/inputs';

function reducer(state, action) {
  switch (action.type) {
    case 'setSourceAccount': {
      return {
        ...state,
        sourceAccount: action.payload
      };
    }
    case 'setHostFunction': {
      return {
        ...state,
        hostFunction: action.payload,
      };
    }
    case 'setCode': {
      return {
        ...state,
        code: action.payload,
      };
    }
    case 'setCodeHash': {
      return {
        ...state,
        codeHash: action.payload
      };
    }
    case 'setFootprint': {
      return {
        ...state,
        footprint: action.payload,
      };
    }
    case 'setContractId': {
      return {
        ...state,
        contractId: action.payload,
      };
    }
    case 'setFunctionName': {
      return {
        ...state,
        functionName: action.payload,
      };
    }
    case 'setFunctionArguments': {
      return {
        ...state,
        functionArguments: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

function useParameters(needsFootprint = false) {
  const context = useContext(Context);

  const [state, dispatch] = useReducer(reducer, {
    sourceAccount: context.parameters.sourceAccount || null,
    hostFunction: context.parameters.hostFunction || null,
    code: context.parameters.code || null,
    codeHash: context.parameters.codeHash || null,
    footprint: needsFootprint ? {type: 'automatic'} : null,
    contractId: context.parameters.contractId || null,
    functionName: context.parameters.functionName || null,
    functionArguments: [
      // {type: 'scvStatic', raw: 'scsTrue'}
    ]
      // {type: 'scvU63'}
      // {type: 'scvStatic'}
      // {type: 'scvObject', raw: {type: 'scoU128'}}
      // {type: 'scvObject'}
      // {type: 'scvBitset'}
  });

  const onCodeChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const bytes = new Uint8Array(event.target.result);
      dispatch({
        type: 'setCode',
        payload: bytes
      });
    };
    fileReader.readAsArrayBuffer(file);
  };

  const parameters = [
    <Text
      name="sourceAccount"
      placeholder="Secret key"
      description={
        <>
          Secret key of an existing Stellar account formatted as <code>S...</code>.
        </>
      }
      value={state.sourceAccount}
      onChange={(nextSecretKey) => dispatch({
        type: 'setSourceAccount',
        payload: nextSecretKey
      })}
    />,
    <Options
      name="hostFunction"
      placeholder="Host function"
      options={[
        {
          value: 'InvokeContract',
          title: 'Invoke contract'
        },
        {
          value: 'CreateContract',
          title: 'Create contract'
        },
        {
          value: 'InstallContractCode',
          title: 'Install contract code'
        }
      ]}
      value={state.hostFunction}
      onChange={(nextFunction) => dispatch({
        type: 'setHostFunction',
        payload: nextFunction
      })}
    />,
  ];

  if (state.hostFunction === 'InvokeContract') {
    parameters.push(
      <Text
        name="contractId"
        placeholder="Contract ID"
        description="Soroban contract ID, encoded in hexadecimal."
        value={state.contractId}
        onChange={(nextId) => dispatch({
          type: 'setContractId',
          payload: nextId
        })}
      />,
      <Text
        name="functionName"
        placeholder="Function name"
        description="Soroban contract function name."
        value={state.functionName}
        onChange={(nextName) => dispatch({
          type: 'setFunctionName',
          payload: nextName
        })}
      />,
      <InputArray
        name="functionArguments"
        label="Function arguments"
        input={{
          type: SorobanValue
        }}
        value={state.functionArguments}
        onChange={(nextArguments) => dispatch({
          type: 'setFunctionArguments',
          payload: nextArguments
        })}
      />
    )
  } else if (state.hostFunction === 'CreateContract') {
    parameters.push(
      <Text
        name="codeHash"
        placeholder="Contract code hash"
        description={
          <>The SHA-256 hash of the contract binary, the <code>*.wasm</code> file, encoded in hexadecimal.</>
        }
        value={state.codeHash}
        onChange={(nextHash) => dispatch({
          type: 'setCodeHash',
          payload: nextHash
        })}
      />
    );
  } else if (state.hostFunction === 'InstallContractCode') {
    parameters.push(
      <File
        name="code"
        placeholder="Compiled contract"
        description={
          <>
            Upload the <code>*.wasm</code> file, located in your project's <code>target/wasm32-unknown-unknown/release/</code> folder.
          </>
        }
        isBinary={true}
        value={state.code}
        onChange={onCodeChange}
      />
    );
  }

  // Add footprint field, if needed.
  if (needsFootprint) {
    parameters.push(
      <TypedText
        name="footprint"
        label="Footprint"
        description="The ledger footprint of this transaction, encoded in Base 64."
        types={[
          {
            name: 'automatic',
            title: 'Automatic',
            isIdentity: true
          },
          {
            name: 'manual',
            title: 'Manual'
          }
        ]}
        value={state.footprint}
        onChange={(nextFootprint) => dispatch({
          type: 'setFootprint',
          payload: nextFootprint
        })}
      />
    );
  }

  return [state, parameters];
}

export {useParameters};