import {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {GameContext} from '../Context.jsx';

function Library(props) {
  const gameContext = useContext(GameContext);

  useEffect(() => {
    gameContext.load();
  }, []);

  return (
    <div>
      <h1>Library</h1>
      {gameContext.grids?.map((grid, index) => {
        const encodedGrid = grid.encoded();

        return (
          <div key={index}>
            <Link to={`/apps/game/canvas?grid=${encodedGrid}`}>
              {encodedGrid}
            </Link>
          </div>
        );
      })}
      {!gameContext.grids && gameContext.account &&
        <div>Loading...</div>
      }
    </div>
  );
}

export {Library};