import {css, cx} from '@emotion/css';

import {material} from '../utilities/colors';

const styles = {
  annotation: css({
    margin: '16px 0',
    padding: '16px',
    border: `1px solid ${material.lightBlue[100]}`,
    borderLeft: `4px solid ${material.lightBlue[300]}`,
    borderRadius: '4px',
    backgroundColor: material.lightBlue[50],
    fontSize: '14px',
  }),
};

function Annotation(props) {
  return (
    <div className={cx(styles.annotation)}>
      {props.children}
    </div>
  );
}

export {Annotation};