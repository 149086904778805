import {css, cx, keyframes} from '@emotion/css';
import {useState} from 'react';

import {material} from '../utilities/colors';
import {media} from '../utilities/device.js';

const animations = {
  rotate: keyframes({
    '0%': {
      transform: 'translateY(4px)'
    },
    '20%': {
      transform: 'translateY(16px)'
    },
    '40%': {
      transform: 'translateY(-4px)'
    },
    '60%': {
      transform: 'translateY(8px)'
    },
    '80%': {
      transform: 'translateY(4px)'
    },
    '100%': {
      transform: 'translateY(0px)'
    },
  })
};

const styles = {
  container: css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    position: 'relative',
    margin: '0 0 32px 0',
  }),
  header: css({
    gridColumn: '1 / span 2',
    gridRow: '1 / 1',
    display: 'flex',
    flexDirection: 'row',
  }),
  tab: css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0px 0px',
    padding: '4px 16px',
    borderRadius: '3px 3px 0 0',
    backgroundColor: material.grey[50],
    color: material.pink[500],
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'monospace',
  }),
  actions: css({
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '4px 0px 4px 8px',
  }),
  actionEffect: css({
    zIndex: '10',
    animation: `${animations.rotate} 500ms ease-in-out`,
  }),
  code: css({
    gridColumn: '2 / 2',
    gridRow: '2 / 2',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
    padding: '0 !important',
    backgroundColor: material.grey[100],
    borderRadius: '0 4px 4px 0',
    fontSize: '14px',
    [media.mobile]: {
      margin: '0 -12px 0px -12px',
      borderRadius: '0px',
      borderWidth: '1px 0 1px 0',
    },
  }),
  lineNumbers: css({
    gridColumn: '1 / 1',
    gridRow: '2 / 2',
    margin: '0',
    padding: '16px 8px 16px 16px',
    textAlign: 'right',
    color: material.grey[400],
    backgroundColor: material.grey[50],
    borderRadius: '4px 0 0 4px',
    userSelect: 'none',
    [media.mobile]: {
      display: 'none',
      borderRadius: '0',
    },
  }),
  lineNumbersWithCommand: css({
    color: material.deepPurple[400],
  }),
  lineNumbersWithTab: css({
    borderTopLeftRadius: '0',
  }),
  highlightedLineNumber: css({
    color: material.lightBlue[400],
  }),
  source: css({
    flex: '1',
    padding: '16px 0 16px 0',
    [media.mobile]: {
      padding: '16px 0 16px 0',
    }
  }),
  // TODO: delete candidate, couldn't find usage.
  // isTight: css({
  //   padding: '16px 16px 16px 8px',
  //   [media.mobile]: {
  //     padding: '16px 16px 16px 16px',
  //   }
  // }),
  line: css({
    height: '21px',
    padding: '0 16px 0 8px',
    [media.mobile]: {
      padding: '0 24px 0 24px',
    }
  }),
  highlightedLine: css({
    backgroundColor: material.lightBlue[100],
  }),
  continuationLine: css({
    display: 'flex',
    alignItems: 'center',
    color: material.grey[500],
    fontSize: '12px',
    fontFamily: 'var(--bs-body-font-family)',
    fontStyle: 'italic',
  }),
};

function Code(props) {
  const source = props.source.trim();
  const code = [];

  for (const line of source.split('\n')) {
    code.push(line);
  }
  
  const isCommand = props.language === 'language-sh';

  const [isFocused, setFocused] = useState(false);
  const [wasCopied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(false);
    navigator.clipboard.writeText(source).then(() => {
      setCopied(true);
    });
  };

  return (
    <div
      className={cx(styles.container)}
      onMouseOver={() => setFocused(true)}
      onMouseOut={() => setFocused(false)}
    >
      <div className={cx(styles.header)}>
        {props.filename &&
          <div className={cx(styles.tab)}>
            {props.filename}
          </div>
        }
        {props.language &&
          <div className={cx(styles.actions)}>
            <button
              type="button"
              className={cx({
                'btn': true,
                'btn-sm': true,
                'btn-primary': isFocused,
                'btn-light': !isFocused,
                [styles.actionEffect]: wasCopied
              })}
              onClick={onCopy}
            >
              copy
            </button>
          </div>
        }
      </div>
      
      <pre
        className={cx({
          [styles.lineNumbers]: true,
          [styles.lineNumbersWithCommand]: isCommand,
          [styles.lineNumbersWithTab]: props.filename !== undefined && props.filename !== null
        })}
      >
        {isCommand &&
          <span>$</span>
        }
        {props.lineNumberOffset !== undefined && props.filename &&
          <div>⋮</div>
        }
        {!isCommand && code.map((line, index) => {
          return (
            <div
              key={`line-number-${index}`}
              className={cx({
                [styles.highlightedLineNumber]: props.highlights && props.highlights.includes(index+1)
              })}
            >
              {(Number.parseInt(props.lineNumberOffset || 1)) + index}
            </div>
          );
        })}
      </pre>

      <pre
        className={cx({
          [styles.code]: true
        })}
      >
        <code
          className={cx({
            [styles.source]: true,
            // [styles.isTight]: props.isTight
          })}
        >
          {props.lineNumberOffset !== undefined && props.filename &&
            <div className={cx(styles.line, styles.continuationLine)}>
              Continued
            </div>
          }
          {code.map((line, index) => {
            return (
              <div
                key={`line-${index}`}
                className={cx({
                  [styles.line]: true,
                  [styles.highlightedLine]: props.highlights && props.highlights.includes(index+1)
                })}
              >
                {line}
              </div>
            );
          })}
        </code>
      </pre>
    </div>
  );
}

export {Code};