import {useState, useEffect, useContext} from 'react';
import {css, cx, keyframes} from '@emotion/css';
import {useLocation} from 'react-router-dom';

import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';
import {Account} from '../../../../packages/stellar/Account.js';

import {Grid} from '../Grid.js';

import {GameContext} from '../Context.jsx';

import {VisualGrid} from '../components/VisualGrid.jsx';
import {Button} from '../Button.jsx';

import {material} from '../../../../utilities/colors/material.js';

const animations = {
  blink: keyframes({
    '0%': {
      opacity: '1'
    },
    '50%': {
      opacity: '0.5'
    },
    '100%': {
      opacity: '1'
    }
  })
}

const styles = {
};

function Canvas(props) {
  const location = useLocation();
  const gameContext = useContext(GameContext);

  const [grid, setGrid] = useState(() => {
    const search = new URLSearchParams(location.search);
    if (search.get('grid')) {
      const encodedGrid = Number.parseInt(search.get('grid'));
      return Grid.fromInteger(encodedGrid, gameContext.settings.grid.length);
    } else {
      return new Grid(gameContext.settings.grid.length);
    }
  });

  const reset = () => {
    setGrid(new Grid(gameContext.settings.grid.length));
  };

  const onCanvasChange = (nextGrid) => {
    if (!gameContext.isLoading && !gameContext.isSaving) {
      setGrid(nextGrid);
    }
  };

  return (
    <>
      <div className={cx(styles.grid)}>
        <VisualGrid
          grid={grid}
          onChange={onCanvasChange}
        />
      </div>
      {gameContext.isLoading &&
        <h6>Loading...</h6>
      }
      {gameContext.isSaving &&
        <h6>Saving...</h6>
      }
      {gameContext.account &&
        <div>
          <Button
            onClick={() => gameContext.add(grid)}
            type={"primary"}
            isWaiting={gameContext.isSaving}
            title="save"
          />
          <Button
            onClick={reset}
            type={"secondary"}
            title="reset"
          />
        </div>
      }
    </>
  );
}

export {Canvas};