import { cx } from '@emotion/css';
import {useNavigate, useLocation} from 'react-router-dom';

const routes = [
  {
    title: 'Canvas', path: '/apps/game/canvas'
  },
  {
    title: 'Library', path: '/apps/game/library'
  }
];

function Navigation(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ul className="nav nav-tabs">
      {routes.map((route, index) => {
        return (
          <li
            key={index}
            className="nav-item"
            onClick={() => navigate(route.path)}
          >
            <span
              className={cx({
                'nav-link': true,
                'active': location.pathname === route.path
              })}
            >
              {route.title}
            </span>
          </li>
        );
      })}
    </ul>
  );
}

export {Navigation};