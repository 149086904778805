import {NavLink as Link} from 'react-router-dom';

import {Guide} from './components/Guide.jsx';
import {Markdown} from '../../components/Markdown.jsx';
import {Annotation} from '../../components/Annotation.jsx';

const content = `
### Soroban CLI

Previously, in the [Testing guide](/guides/testing), we interacted with our Soroban contract using the \`Env::default()\`, registering the contract with in and then calling a function on the contract client instance. With the [\`soroban-cli\`][1], we can interact with our contracts using the command line.

First, let's install the latest version of the \`soroban-cli\`.

~~~sh
cargo install --locked --version 0.4.0 soroban-cli
~~~

Of course make sure you have built the contract using the Cargo command.

~~~sh
cargo build --target wasm32-unknown-unknown --release
~~~

For example, we can invoke the contract's \`add\` function with the same arguments as in the final test using the \`invoke\` subcommand. The main argument is \`--wasm path/to/compiled.wasm\`, which points the \`soroban-cli\` to your contract's compiled executable. The \`--id\` argument is also necessary, but since we're running code in the sandbox, you can provide whatever ID you want, the CLI will just add the contract to the blockchain using the provided ID, [reference][6].

~~~sh
soroban invoke \\
  --wasm target/wasm32-unknown-unknown/release/project.wasm \\
  --id 1 \\
  --fn add \\
  --arg 1 \\
  --arg 2
~~~

The result should be the same as before.

~~~
3
~~~

I was also curious as to how the \`soroban-cli\` works, and since it's a Rust program, it's a good exercise to see under the hood and see if we can understand everything. I won't go into detail here, just sharing the trace from the command definition to actual execution.

1. [\`soroban-cli/src/main.rs\`][2] | \`Cmd::Invoke(invoke::Cmd)\`
2. [\`soroban-cli/src/main.rs\`][3] | \`fn run()\`
3. [\`soroban-cli/src/invoke.rs\`][4] | \`Cmd::fn run()\`
4. [\`soroban-cli/src/invoke.rs\`][5] | \`Cmd::fn run_in_sandbox()\`
5. [\`soroban-env-host/src/host.rs\`][7] | \`Host::fn invoke_function()\`
6. [\`soroban-env-host/src/host.rs\`][8] | \`Host::fn invoke_function_raw()\`
7. [\`soroban-env-host/src/host.rs\`][9] | \`Host::fn call_n()\`
8. [\`soroban-env-host/src/host.rs\`][10] | \`Host::fn call_contract_fn()\`
8. [\`soroban-env-host/src/vm.rs\`][11] | \`Vm::fn invoke_function_raw()\`

At this point we leave Soroban world and enter into [\`wasmi\`][12], which is a WebAssembly interpreter that Soroban uses. Since this is where the WASM execution begins, this is where I stop going further down the trace.

1. [\`wasmi/src/func/mod.rs\`][14] | \`fn call<T>()\`

#### Subcommands

I went through all available Soroban CLI subcommands and compiled documentation for each one, along with sample inputs and results.

##### \`soroban invoke\`

Invoke a Soroban contract function in a WASM file. Previously we called a more succinct version of this subcommand, with a couple more options you can get a full accounting of the \`--cost\` of executing this function, as well as the data \`--footprint\` of the invocation.

~~~sh
soroban invoke \\
  --wasm target/wasm32-unknown-unknown/release/project.wasm \\
  --id 1 \\
  --fn add \\
  --arg 1 \\
  --arg 2 \\
  --cost \\
  --footprint
~~~

A little more insight into what information \`--footprint\` provides.

> It displays the footprint of the transaction, which encompasses all the data that all the contracts that are called in the transaction read and write (so not only the one directly invoked). *https://discord.com/channels/897514728459468821/966788672164855829/1045430667808354375|tdep#4794|discord.gg/stellardev*

It's really interesting to see all the costs broken down, item-by-item. This can be really useful when optimizing transaction costs.

~~~
Footprint: {
  "readOnly": [
    {
      "contractData": {
        "contractId": "0000000000000000000000000000000000000000000000000000000000000001",
        "key": {
          "static": "ledgerKeyContractCode"
        }
      }
    }
  ],
  "readWrite": []
}
Cpu Insns: 122950
Mem Bytes: 2049
Cost (WasmInsnExec): 100
Cost (WasmMemAlloc): 0
Cost (HostEventDebug): 0
Cost (HostEventContract): 0
Cost (HostFunction): 2
Cost (VisitObject): 3
Cost (PushFrame): 2
Cost (PopFrame): 2
Cost (ValXdrConv): 6
Cost (ValSer): 0
Cost (ValDeser): 0
Cost (CloneEvents): 0
Cost (HostObjAllocSlot): 3
Cost (HostVecAllocCell): 0
Cost (HostMapAllocCell): 0
Cost (HostU64AllocCell): 0
Cost (HostI64AllocCell): 2
Cost (HostBytesAllocCell): 32
Cost (HostBigIntAllocCell): 0
Cost (ComputeSha256Hash): 0
Cost (ComputeEd25519PubKey): 0
Cost (ImMapNew): 0
Cost (ImMapMutEntry): 0
Cost (ImMapImmutEntry): 0
Cost (ImVecNew): 0
Cost (ImVecMutEntry): 0
Cost (ImVecImmutEntry): 0
Cost (ScVecFromHostVec): 0
Cost (ScMapFromHostMap): 0
Cost (ScVecToHostVec): 0
Cost (ScMapToHostMap): 0
Cost (GuardFrame): 2
Cost (CloneVm): 0
Cost (VerifyEd25519Sig): 0
Cost (BigIntNew): 0
Cost (BigIntAddSub): 0
Cost (BigIntMul): 0
Cost (BigIntDivRem): 0
Cost (BigIntBitwiseOp): 0
Cost (BigIntShift): 0
Cost (BigIntCmp): 0
Cost (BigIntGcdLcm): 0
Cost (BigIntPow): 0
Cost (BigIntPowMod): 0
Cost (BigIntSqrt): 0
Cost (BigIntFromBytes): 0
Cost (BigIntToBytes): 0
Cost (BigIntToRadix): 0
Cost (VmMemCpy): 0
Cost (VmInstantiation): 481
Cost (VmInvokeFunction): 2
Cost (BytesClone): 128
Cost (BytesDel): 0
Cost (BytesPush): 0
Cost (BytesPop): 0
Cost (BytesInsert): 0
Cost (BytesAppend): 0
Cost (BytesSlice): 0
Cost (BytesConcat): 0
Cost (CallArgsUnpack): 2
Cost (ChargeBudget): 0
Cost (HostContractCodeAllocCell): 0
Cost (HostAccountIdAllocCell): 0
Cost (ImMapCmp): 0
Cost (ImVecCmp): 0
Cost (BytesCmp): 0
Cost (EdwardsPointCurve25519ScalarMul): 0
~~~

##### \`soroban inspect\`

Inspect your contract's \`.wasm\` file and list contract functions, metadata, etc.

~~~sh
soroban inspect \\
  --wasm target/wasm32-unknown-unknown/release/project.wasm
~~~

~~~
File: target/wasm32-unknown-unknown/release/project.wasm
Env Meta: AAAAAAAAAAAAAAAX
 • Interface Version: 23
Contract Spec: AAAAAAAAAANhZGQAAAAAAgAAAARsZWZ0AAAABAAAAAVyaWdodAAAAAAAAAQAAAABAAAABA==
 • Function: add ([ScSpecFunctionInputV0 { name: StringM(left), type_: I64 }, ScSpecFunctionInputV0 { name: StringM(right), type_: I64 }]) -> ([I64])
~~~

##### \`soroban optimize\`

Runs an optimization program on your contract's \`.wasm\`.

~~~sh
soroban optimize \\
  --wasm target/wasm32-unknown-unknown/release/project.wasm
~~~

~~~
Reading: target/wasm32-unknown-unknown/release/project.wasm (481 bytes)
Writing to: target/wasm32-unknown-unknown/release/project.wasm...
Optimized: target/wasm32-unknown-unknown/release/project.optimized.wasm (422 bytes)
~~~

As you can see this reduced the contract's size by \`59\` bytes, which is always nice.

##### \`soroban serve\`

This subcommand launches a local webserver that provides an HTTP interface for your local app development needs.

~~~sh
soroban serve
~~~

~~~
Listening on: 127.0.0.1:8000/soroban/rpc
~~~

##### \`soroban token\`

Wrap, create, and manage token contracts. I will explore this in a later guide.

~~~sh
soroban token \\
  create \\
  --name SorobanCoin \\
  --symbol SOCO
~~~

This creates and deployes a new token contract and provides us with the contract ID.

~~~
d55b5a3a5793539545f957f7da783f7b19159369ccdb19c53dbd117ebfc08842
~~~

##### \`soroban read\`

Looks in the ledger for contract data and prints it, if any.

~~~sh
soroban read \\
  --id 1
~~~

After invoking our contract, since it doesn't modify the ledger's state, we don't really have any data, so we get no output from running this subcommand.

~~~
~~~

However, if you've executed the above \`token\` subcommand and run \`read\` with the ID given, we'll get a more useful output.

~~~sh
soroban read \\
  --id d55b5a3a5793539545f957f7da783f7b19159369ccdb19c53dbd117ebfc08842
~~~

~~~
"[""Admin""]","[""Account"",""GAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWHF""]"
"[""Metadata""]","[""Token"",{""decimals"":7,""name"":[83,111,114,111,98,97,110,67,111,105,110],""symbol"":[83,79,67,79]}]"
~~~

If you're curious, you can take a look at the \`.soroban/ledger.json\` which maintains the sandbox ledger's state.

##### \`soroban deploy\`

This subcommand deploys your contract's \`.wasm\` to a ledger, by default to the \`.soroban/ledger.json\` local sandbox ledger.

~~~sh
soroban deploy \\
  --wasm target/wasm32-unknown-unknown/release/project.wasm
~~~

Output is the newly assigned contract ID.

~~~
a3692d24e6540f7604b6c270953981e63dc2b09025c088c2e4cbe8c31cad80fe
~~~

##### \`soroban gen\`

Generates code client bindings for a contract.

~~~sh
soroban gen \\
  --wasm  target/wasm32-unknown-unknown/release/project.wasm \\
  --output json
~~~

This will return a JSON string to the terminal, you can also choose to have the \`--output\` in \`rust\`.

~~~
{
  "type": "function",
  "name": "add",
  "inputs": [
    {
      "name": "left",
      "value": {
        "type": "i64"
      }
    },
    {
      "name": "right",
      "value": {
        "type": "i64"
      }
    }
  ],
  "outputs": [
    {
      "type": "i64"
    }
  ]
}
~~~

##### \`soroban xdr\`

Stellar, the Soroban blockchain, uses the [*XDR*][15] format to store ledger data, transactions, results, history, and messages in binary form. This is a useful utility when you start writing contracts that interact with the blockchain.

I've copied XDR from a random Stellar transaction.

~~~sh
soroban xdr \\
  dec \\
  --type TransactionEnvelope \\
  --xdr AAAAAgAAAACgtt1m69pFSv34gRFlLimKLIyr77elkhO4XML37Lwj5QAPQkAAAAGbAAABMAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAABAAAAABB90WssODNIgi6BHveqzxTRmIpvAFRyVNM+Hm2GVuCcAAAAAAAAAAAcui8upurW/6+4TIXxRMpGfQ/dySraYGreiy0aT0HWUwAAABdIdugAAAAAAAAAAALsvCPlAAAAQOQYjWNKzy2g+bwOMkd5ugKUqfZ2QVFm6zmOKCyVs+z/DZzYujIn51s+TifdlqkiS9hxTwmZ2qafv6tS3lw8bweGVuCcAAAAQDnpQhm2sZ2QSZ9UFBW4sN2arcRuO0B+dW63r4JYSgB0cTtCLvh3utR+Z3hebqcdwJZIqXEO0Y6mZ9xo8mQ5/AU=
~~~

The full output is below. Personally, I would use the [XDR Viewer][16] to decode XDR to human readable code.

~~~
TransactionEnvelope(
  Tx(
      TransactionV1Envelope {
          tx: Transaction {
              source_account: Ed25519(
                  Uint256(a0b6dd66ebda454afdf88111652e298a2c8cabefb7a59213b85cc2f7ecbc23e5),
              ),
              fee: 1000000,
              seq_num: SequenceNumber(
                  1765231558960,
              ),
              cond: Time(
                  TimeBounds {
                      min_time: TimePoint(
                          0,
                      ),
                      max_time: TimePoint(
                          0,
                      ),
                  },
              ),
              memo: None,
              operations: VecM(
                  [
                      Operation {
                          source_account: Some(
                              Ed25519(
                                  Uint256(107dd16b2c383348822e811ef7aacf14d1988a6f00547254d33e1e6d8656e09c),
                              ),
                          ),
                          body: CreateAccount(
                              CreateAccountOp {
                                  destination: AccountId(
                                      PublicKeyTypeEd25519(
                                          Uint256(1cba2f2ea6ead6ffafb84c85f144ca467d0fddc92ada606ade8b2d1a4f41d653),
                                      ),
                                  ),
                                  starting_balance: 100000000000,
                              },
                          ),
                      },
                  ],
              ),
              ext: V0,
          },
          signatures: VecM(
              [
                  DecoratedSignature {
                      hint: SignatureHint(ecbc23e5),
                      signature: Signature(
                          BytesM(e4188d634acf2da0f9bc0e324779ba0294a9f676415166eb398e282c95b3ecff0d9cd8ba3227e75b3e4e27dd96a9224bd8714f0999daa69fbfab52de5c3c6f07),
                      ),
                  },
                  DecoratedSignature {
                      hint: SignatureHint(8656e09c),
                      signature: Signature(
                          BytesM(39e94219b6b19d90499f541415b8b0dd9aadc46e3b407e756eb7af82584a0074713b422ef877bad47e67785e6ea71dc09648a9710ed18ea667dc68f26439fc05),
                      ),
                  },
              ],
          ),
      },
  ),
)
~~~

##### \`soroban version\`

Self explanatory - prints the Soroban CLI version.

~~~sh
soroban version
~~~

~~~
soroban 0.4.0 (9e680fa53ad7b29b5c951cb0ca38c90cdf21cbea)
soroban-env 0.0.12 (65498c84ccc30df302c99b50af31752f7aa087e8)
soroban-env interface version 27
stellar-xdr 0.0.12 (154e07ebbb0ad307475fd665d5a0dcf169a9596f)
xdr next (026c9cd074bdb28ddde8ee52f2a4502d9e518a09)
~~~

##### \`soroban completion\`

If you want to enable autocompletion for the Soroban CLI subcommands, options, etc., then running this command for your shell (terminal program) will return the script and instructions for how to achieve that.

~~~sh
soroban shell \\
  --shell zsh
~~~

~~~
... loooots of bash code that I won't put here because it's not relevant ...
~~~

And that's it, that's the full spectrum of Soroban CLI subcommands.

[1]: https://github.com/stellar/soroban-tools/blob/main/README.md
[2]: https://github.com/stellar/soroban-tools/blob/main/cmd/soroban-cli/src/main.rs#L41
[3]: https://github.com/stellar/soroban-tools/blob/main/cmd/soroban-cli/src/main.rs#L94
[4]: https://github.com/stellar/soroban-tools/blob/main/cmd/soroban-cli/src/invoke.rs#L257
[5]: https://github.com/stellar/soroban-tools/blob/main/cmd/soroban-cli/src/invoke.rs#L357
[6]: https://github.com/stellar/soroban-tools/blob/main/cmd/soroban-cli/src/invoke.rs#L376
[7]: https://github.com/stellar/rs-soroban-env/blob/main/soroban-env-host/src/host.rs#L970
[8]: https://github.com/stellar/rs-soroban-env/blob/main/soroban-env-host/src/host.rs#L932
[9]: https://github.com/stellar/rs-soroban-env/blob/main/soroban-env-host/src/host.rs#L815
[10]: https://github.com/stellar/rs-soroban-env/blob/main/soroban-env-host/src/host.rs#L784
[11]: https://github.com/stellar/rs-soroban-env/blob/main/soroban-env-host/src/vm.rs#L229
[12]: https://github.com/paritytech/wasmi
[13]: https://github.com/paritytech/wasmi/blob/master/crates/wasmi/src/instance.rs#L102
[14]: https://github.com/paritytech/wasmi/blob/master/crates/wasmi/src/func/mod.rs#L285
[15]: https://developers.stellar.org/docs/encyclopedia/xdr
[16]: https://laboratory.stellar.org/#xdr-viewer
`;



function SorobanCLI() {
  return (
    <Guide>
      <Annotation>
      This guide was created by following the <a href="https://soroban.stellar.org/docs/tutorials/run-on-sandbox" target="_blank" rel="noreferrer">Run on Sandbox</a> tutorial as well as the <a href="https://soroban.stellar.org/docs/getting-started/setup#install-the-soroban-cli" target="_blank" rel="noreferrer">Getting Started</a> guide from the official Soroban documentation.
      </Annotation>
      <p className="fs-6 text-muted">November 24, 2022</p>
      <Markdown>
        {content}
      </Markdown>
      
      <div className="card">
        <div className="card-body">
          <h6 className="card-subtitle mb-2 text-muted">Next guide</h6>
          <Link className="btn btn-primary" to="/guides/stellar-network">Stellar Network</Link>
        </div>
      </div>
    </Guide>
  );
}

export {SorobanCLI};