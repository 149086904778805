import * as hex from '../../../utilities/hex.js';

import Stellar from '../../../soroban/xdr/stellar.js';

/*
union HostFunction switch (HostFunctionType type)
{
case HOST_FUNCTION_TYPE_INVOKE_CONTRACT:
    SCVec invokeArgs;
case HOST_FUNCTION_TYPE_CREATE_CONTRACT:
    CreateContractArgs createContractArgs;
case HOST_FUNCTION_TYPE_INSTALL_CONTRACT_CODE:
    InstallContractCodeArgs installContractCodeArgs;
};

struct CreateContractArgs
{
    ContractID contractID;
    SCContractCode source;
};

union ContractID switch (ContractIDType type)
{
case CONTRACT_ID_FROM_SOURCE_ACCOUNT:
    uint256 salt;
case CONTRACT_ID_FROM_ED25519_PUBLIC_KEY:
    struct 
    {
        uint256 key;
        Signature signature;
        uint256 salt;
    } fromEd25519PublicKey;
case CONTRACT_ID_FROM_ASSET:
    Asset asset;
};

struct
{
    Hash networkID;
    AccountID sourceAccount;
    uint256 salt;
}

union SCContractCode switch (SCContractCodeType type)
{
case SCCONTRACT_CODE_WASM_REF:
    Hash wasm_id;
case SCCONTRACT_CODE_TOKEN:
    void;
};

union LedgerKey switch (LedgerEntryType type)
{
case ACCOUNT:
    struct
    {
        AccountID accountID;
    } account;

case TRUSTLINE:
    struct
    {
        AccountID accountID;
        TrustLineAsset asset;
    } trustLine;

case OFFER:
    struct
    {
        AccountID sellerID;
        int64 offerID;
    } offer;

case DATA:
    struct
    {
        AccountID accountID;
        string64 dataName;
    } data;

case CLAIMABLE_BALANCE:
    struct
    {
        ClaimableBalanceID balanceID;
    } claimableBalance;

case LIQUIDITY_POOL:
    struct
    {
        PoolID liquidityPoolID;
    } liquidityPool;
case CONTRACT_DATA:
    struct
    {
        Hash contractID;
        SCVal key;
    } contractData;
case CONTRACT_CODE:
    struct
    {
        Hash hash;
    } contractCode;
case CONFIG_SETTING:
    struct
    {
        ConfigSettingID configSettingID;
    } configSetting;
};

struct
{
    Hash contractID;
    SCVal key;
}

union SCVal switch (SCValType type)
{
case SCV_U63:
    int64 u63;
case SCV_U32:
    uint32 u32;
case SCV_I32:
    int32 i32;
case SCV_STATIC:
    SCStatic ic;
case SCV_OBJECT:
    SCObject* obj;
case SCV_SYMBOL:
    SCSymbol sym;
case SCV_BITSET:
    uint64 bits;
case SCV_STATUS:
    SCStatus status;
};

enum SCStatic
{
    SCS_VOID = 0,
    SCS_TRUE = 1,
    SCS_FALSE = 2,
    SCS_LEDGER_KEY_CONTRACT_CODE = 3
};
*/

class CreateContract {
  constructor() {
    this._code = null;
    this._codeHash = null;
    this._network = null;
    this._sourceAccount = null;
  }

  async serialized() {
    await this._network.hydrate();

    const salt = crypto.getRandomValues(new Uint8Array(32));
    const preimage = Stellar.HashIdPreimage.envelopeTypeContractIdFromSourceAccount(
      new Stellar.HashIdPreimageSourceAccountContractId({
        networkId: this._network.id,
        sourceAccount: Stellar.AccountId.publicKeyTypeEd25519(this._sourceAccount.keys.public),
        salt
      })
    );
    const contractId = new Uint8Array(
      await crypto.subtle.digest('SHA-256', preimage.toXDR())
    );

    const codeHash = this._code ? await this._code.hash() : hex.decode(this._codeHash);

    return new Stellar.Operation({
      body: Stellar.OperationBody.invokeHostFunction(
        new Stellar.InvokeHostFunctionOp({
          function: Stellar.HostFunction.hostFunctionTypeCreateContract(
            new Stellar.CreateContractArgs({
              contractId: Stellar.ContractId.contractIdFromSourceAccount(
                salt
              ),
              source: Stellar.ScContractCode.sccontractCodeWasmRef(
                codeHash
              )
            })
          ),
          footprint: new Stellar.LedgerFootprint({
            readOnly: [
              Stellar.LedgerKey.contractCode(new Stellar.LedgerKeyContractCode({
                hash: codeHash
              }))
            ],
            readWrite: [
              Stellar.LedgerKey.contractData(new Stellar.LedgerKeyContractData({
                contractId,
                key: Stellar.ScVal.scvStatic(
                  Stellar.ScStatic.scsLedgerKeyContractCode()
                )
              }))
            ]
          })
        })
      )
    });
  }

  code(code) {
    this._code = code;
    return this;
  }
  codeHash(codeHash) {
    this._codeHash = codeHash;
    return this;
  }
  network(network) {
    this._network = network;
    return this;
  }
  sourceAccount(account) {
    this._sourceAccount = account;
    return this;
  }
}

export {CreateContract};