import {useEffect, useReducer} from 'react';
import {useLocation, Route, Routes} from 'react-router-dom';

import {SorobanProvider, useSoroban} from '../../../packages/soroban/Soroban.jsx';

import structure from './structure.json';

import {useNavigation} from '../../../components/Navigation/Context.jsx';

import {Context} from './Context.jsx';

import {Home} from './Home';
import * as methods from './methods';

function reducer(state, action) {
  switch (action.type) {
    case 'setRemoteUrl': {
      return {
        ...state,
        remoteUrl: action.payload
      };
    }
    case 'setNetworkPassphrase': {
      return {
        ...state,
        networkPassphrase: action.payload
      };
    }
    case 'toggleParametersInUrl': {
      return {
        ...state,
        parametersInUrl: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

function RemoteTool() {
  const [state, dispatch] = useReducer(reducer, {
    remoteUrl: window.localStorage.getItem('sorobandev.remoteUrl') || 'https://futurenet.sorobandev.com/soroban/rpc',
    networkPassphrase: window.localStorage.getItem('sorobandev.networkPassphrase') || 'Test SDF Future Network ; October 2022',
    parametersInUrl: (
      window.localStorage.getItem('sorobandev.tool.remote.parametersInUrl') ?
      JSON.parse(
        window.localStorage.getItem('sorobandev.tool.remote.parametersInUrl')
      ) :
      true
    )
  });
  const navigation = useNavigation();

  useEffect(() => {
    navigation.showSubordinate({
      path: '/tools/remote',
      structure
    });
  }, [navigation]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameters = Array.from(searchParams.entries())
    .map((entry) => ({
      [entry[0]]: entry[1]
    }))
    .reduce(
      (previous, current) => ({...previous, ...current}),
      {}
    );

  const updateNetwork = (delta) => {
    if (delta.remoteUrl) {
      window.localStorage.setItem('sorobandev.remoteUrl', delta.remoteUrl);
      dispatch({
        type: 'setRemoteUrl',
        payload: delta.remoteUrl
      });
    }
    if (delta.networkPassphrase) {
      window.localStorage.setItem('sorobandev.networkPassphrase', delta.networkPassphrase);
      dispatch({
        type: 'setNetworkPassphrase',
        payload: delta.networkPassphrase
      });
    }
  };

  const onToggleParametersInUrl = (isOn) => {
    window.localStorage.setItem('sorobandev.tool.remote.parametersInUrl', JSON.stringify(isOn));
    dispatch({
      type: 'toggleParametersInUrl',
      payload: isOn
    });
  };

  return (
    <div className="container-fluid">
      <div className="row g-2 mb-5">
        <SorobanProvider
          networkPassphrase={state.networkPassphrase}
          remoteUrl={state.remoteUrl}
        >
          <Context.Provider
            value={{
              parameters,
              updateNetwork,
              parametersInUrl: state.parametersInUrl,
              onToggleParametersInUrl
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/get-health" element={<methods.GetHealth />} />
              <Route path="/get-account" element={<methods.GetAccount />} />
              <Route path="/get-latest-ledger" element={<methods.GetLatestLedger />} />
              <Route path="/get-ledger-entry" element={<methods.GetLedgerEntry />} />
              <Route path="/get-network" element={<methods.GetNetwork />} />
              <Route path="/request-airdrop" element={<methods.RequestAirdrop />} />
              <Route path="/get-events" element={<methods.GetEvents />} />
              <Route path="/get-transaction-status" element={<methods.GetTransactionStatus />} />
              <Route path="/send-transaction" element={<methods.SendTransaction />} />
              <Route path="/simulate-transaction" element={<methods.SimulateTransaction />} />
            </Routes>
          </Context.Provider>
        </SorobanProvider>
      </div>
    </div>
  );
}

export {RemoteTool};