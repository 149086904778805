import * as XDR from 'js-xdr';

import Stellar from '../../../packages/soroban/xdr/stellar.js';
import {Keys} from '../../../packages/stellar/Keys.js';

import * as base64 from '../../../packages/utilities/base64.js';

function encodeScVal(value) {
  switch (value.type) {
    case 'scvU63': {
      return Stellar.ScVal.scvU63(
        XDR.Hyper.fromString(`${value.raw}`)
      );
    }

    case 'scvU32': {
      return Stellar.ScVal.scvU32(
        Number.parseInt(value.raw)
      );
    }

    case 'scvI32': {
      return Stellar.ScVal.scvI32(
        Number.parseInt(value.raw)
      );
    }

    case 'scvStatic': {
      return Stellar.ScVal.scvStatic(
        Stellar.ScStatic[value.raw]()
      );
    }

    case 'scvObject': {
      let objectValue = null;

      switch (value.raw.type) {
        case 'scoVec': {
          const vector = value.raw.raw.map((vectorValue) => {
            return encode(vectorValue).encoded;
          });
          objectValue = Stellar.ScObject.scoVec(
            vector
          );
          break;
        }
        case 'scoMap': {
          throw 'Error: maps are not yet suppported.';
        }
        case 'scoU64': {
          objectValue = Stellar.ScObject.scoU64(
            XDR.UnsignedHyper.fromString(value.raw.raw)
          );
          break;
        }
        case 'scoI64': {
          objectValue = Stellar.ScObject.scoI64(
            XDR.Hyper.fromString(value.raw.raw)
          );
          break;
        }
        case 'scoU128': {
          throw 'Error: uint128 is not yet suppported.';
        }
        case 'scoI128': {
          throw 'Error: int128 is not yet suppported.';
        }
        case 'scoBytes': {
          objectValue = Stellar.ScObject.scoBytes(
            base64.decode(value.raw.raw)
          );
          break;
        }
        case 'scoContractCode': {
          throw 'Error: contract code is not yet suppported.';
        }
        case 'scoAccountId': {
          const account = Keys.fromString(value.raw.raw);
          objectValue = Stellar.ScObject.scoAccountId(
            Stellar.AccountId.publicKeyTypeEd25519(account.public)
          );
          break;
        }
        default:
          throw 'Error: unknown Object type.'
      }

      return Stellar.ScVal.scvObject(
        objectValue
      )
    }

    case 'scvSymbol': {
      return Stellar.ScVal.scvSymbol(
        new TextEncoder().encode(value.raw)
      );
    }

    case 'scvBitset': {
      const number = BigInt(`0b${value.raw}`);
      if (number > 18446744073709551615n) {
        throw 'Error: too many bits, must be no more than 64.';
      }

      return Stellar.ScVal.scvBitset(
        XDR.UnsignedHyper.fromString(number.toString())
      );
    }

    case 'scvStatus': {
      throw 'Error: status is not yet suppported.';
    }

    default:
      throw 'Error: unknown type.'
  }
}

function encode(value) {
  const encoded = encodeScVal(value);
  const xdr = encoded.toXDR('base64');

  return {
    encoded,
    xdr
  };
};

export {encode};