import {createContext} from 'react';
import {css, cx} from '@emotion/css';
import {NavLink, useLocation} from 'react-router-dom';

import structure from './structure.json';

import {material} from '../../utilities/colors';
import * as device from '../../utilities/device.js';

import {useNavigation} from './Context.jsx';

const styles = {
  navigation: css({
    // display: 'none',
    flexShrink: '0',
    width: '240px',
    padding: '8px',
    // backgroundColor: 'orange',
    borderRight: `1px solid ${material.grey[200]}`,
    fontSize: '14px',
    [device.media.mobile]: {
      width: '100%',
      padding: '0 16px 16px 16px',
      borderRight: 'none',
    }
  }),
  isHidden: css({
    display: 'none',
    [device.media.desktop]: {
      display: 'block',
    },
    [device.media.tablet]: {
      display: 'block',
    }
  }),
  parentList: css({

  }),
  list: css({
    padding: '0',
    listStyle: 'none',
  }),
  item: css({
    padding: '8px 0 8px 0',
    [device.media.desktop]: {
      padding: '4px 0 0 0',
    },
    [device.media.tablet]: {
      padding: '4px 0 0 0',
    }
  }),
  groupTitle: css({
    color: material.blueGrey[700],
    fontWeight: 'bold',
  }),
  link: css({
    color: material.blueGrey[500],
    textDecoration: 'none',
  }),
  activeLink: css({
    color: material.deepPurple[500],
  }),
  subordinateLink: css({
    color: material.blueGrey[500],
  }),
  subordinateMarked: css({
    margin: '0 4px 0 0',
    color: material.deepPurple[500],
  }),
};

function renderFrom(node, location, subordinate, depth = 0, isSubordinate = false) {
  let childdren = null;

  if (node.children) {
    childdren = (
      <ol
        className={cx(styles.list)}
        style={{
          paddingLeft: `${Math.min(depth, 1) * 16}px`
        }}
      >
        {node.children.map((child, index) => {
          return renderFrom(child, location, subordinate, depth + 1, isSubordinate);
        })}
      </ol>
    );
  }

  if (node.title) {
    const hasSubordinate = subordinate && subordinate.path.startsWith(node.path);
    const isActive = (
      location.pathname === node.path ||
      location.pathname.startsWith(node.path) && hasSubordinate
    );

    return (
      <li key={`${depth}.${node.title}`} className={cx(styles.item)}>
        <div>
          {isSubordinate &&
            <span className={cx(styles.subordinateMarked)}>-</span>
          }
          {node.path &&
            <NavLink
              to={node.path}
              className={cx({
                [styles.link]: true,
                [styles.subordinateLink]: isSubordinate,
                [styles.activeLink]: isActive,
              })}
            >
              {node.title}
            </NavLink>
          }
          {!node.path &&
            <span className={cx(styles.groupTitle)}>
              {node.title}
            </span>
          }
        </div>
        {childdren}
        {isActive && hasSubordinate &&
          renderFrom(subordinate.structure, location, subordinate, depth + 1, true)
        }
      </li>
    );
  } else {
    return childdren;
  }
}

function Navigation(props) {
  const navigation = useNavigation();
  const location = useLocation();

  return (
    <nav
      className={cx({
        [styles.navigation]: true,
        [styles.isHidden]: navigation.isHidden
      })}
    >
      <ol className={cx(styles.list, styles.parentList)}>
        {renderFrom(structure, location, navigation.subordinate)}
      </ol>
    </nav>
  );
}

export {Navigation};