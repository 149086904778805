import {cx} from '@emotion/css';

import {Wrapper} from './components/Wrapper.jsx';

function Options(props) {
  const hasFloatingLabel = props.hasFloatingLabel !== undefined ? props.hasFloatingLabel : true;
  const hasValue = props.value !== null && props.value !== undefined;
  const options = props.options.map((option, index) => {
    return (
      <option
        key={option.value}
        value={option.value}
        disabled={option.isDisabled ? option.isDisabled : false}
      >
        {option.title}
      </option>
    );
  });

  return (
    <Wrapper
      hasFloatingLabel={hasFloatingLabel}
      isGrouped={!!props.isGrouped}
      label={hasFloatingLabel ? props.placeholder : props.label}
    >
      <select
        className={cx('form-select', props.className)}
        value={props.value || ''}
        onChange={(event) => props.onChange(event.target.value)}
      >
        {!hasValue &&
          <>
            <option value='' disabled>
              {props.placeholder}
            </option>
            {options}
          </>
        }
        {hasValue &&
          <optgroup label={props.placeholder}>
            {options}
          </optgroup>
        }
      </select>
    </Wrapper>
  );
}

export {Options};