import {useReducer, useEffect, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';

import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';

import {Context} from '../Context.jsx';

import {Parameter} from './Parameter.jsx';
import {Code} from '../../../../components/Code.jsx';
import {Tabs, Tab} from './Tabs.jsx';
import {Request} from './Request.jsx';
import {Response} from './Response.jsx';

function reducer(state, action) {
  switch(action.type) {
    case 'setRequest': {
      return {
        ...state,
        request: action.payload
      };
    }
    case 'startWaiting': {
      return {
        ...state,
        isWaiting: true,
        response: null,
        error: null
      };
    }
    case 'stopWaiting': {
      return {
        ...state,
        isWaiting: false
      };
    }
    case 'recordError': {
      return {
        ...state,
        error: action.payload
      };
    }
    case 'recordResponse': {
      return {
        ...state,
        response: action.payload,
        activeTab: 2
      };
    }
    case 'setTab': {
      return {
        ...state,
        activeTab: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

function Method(props) {
  const [state, dispatch] = useReducer(reducer, {
    isWaiting: false,
    request: null,
    response: undefined,
    error: null,
    activeTab: 0
  });
  const context = useContext(Context);
  const soroban = useSoroban();

  const [urlParameters, setUrlParameters] = useSearchParams();
  const updateUrlParameters = () => {
    if (props.parameters) {
      const nextUrlParameters = new URLSearchParams();
      for (const parameter of props.parameters) {
        if (
          typeof parameter.props.value === 'string' && parameter.props.value.length <= 64 ||
          typeof parameter.props.value === 'number'
        ) {
          nextUrlParameters.set(parameter.props.name, parameter.props.value);
        }
      }
  
      if (
        urlParameters.toString() !== nextUrlParameters.toString() &&
        context.parametersInUrl
      ) {
        setUrlParameters(nextUrlParameters);
      }
    }
  };

  const changeTab = (nextTab) => {
    dispatch({
      type: 'setTab',
      payload: nextTab
    });
  };

  const loadRequest = async () => {
    const request = await props.request;
    dispatch({
      type: 'setRequest',
      payload: request
    });
  };
  useEffect(() => {
    loadRequest();
  }, [props.request]);

  const onCall = async () => {
    dispatch({type: 'startWaiting'});
    try {
      const response = await soroban.network.executeRequest(state.request);
      dispatch({type: 'stopWaiting'});
      dispatch({
        type: 'recordResponse',
        payload: response
      });
      updateUrlParameters();
    } catch (error) {
      dispatch({type: 'stopWaiting'});
      dispatch({
        type: 'recordError',
        payload: error.message
      })
    }
  };

  return (
    <>
      <h3>Remote</h3>
      <h4>
        {props.isWIP &&
          <small className="fw-light me-2">(WIP)</small>
        }
        <code>{props.name}</code>
      </h4>
      <div className="col-12 mb-3">
        {props.description}
      </div>
      {props.parameters &&
        <>
          <h5>Parameters</h5>
          {props.parameters.map((parameter, index) => {
            return (
              <Parameter key={index} children={parameter} />
            );
          })}
        </>
      }
      {state.error &&
        <div className="col-12 mt-3">
          <h5>Error</h5>
          <p>
            {state.error}
          </p>
        </div>
      }
      <div className="col-12 mt-3 mb-5">
        <button
          type="submit"
          className="btn btn-primary font-monospace"
          onClick={onCall}
          disabled={state.isWaiting}
        >
          {state.isWaiting ? 'calling...' : 'call'}
        </button>
      </div>
      <Tabs activeTab={state.activeTab} onChangeTab={changeTab}>
        <Tab title="Request">
          {state.request &&
            <Request
              url={soroban.network.remoteUrl}
              body={state.request}
              parameters={props.parameters}
            />
          }
        </Tab>
        <Tab title="Response">
          {state.response &&
            <Code
              source={state.response ? JSON.stringify(state.response, null, 2) : '...'}
            />
          }
          {state.response === null && 'Empty...'}
        </Tab>
        <Tab title="Response for Humans">
          {state.response &&
            <Response response={state.response} />
          }
          {state.response === null && 'Empty...'}
        </Tab>
      </Tabs>
    </>
  );
}

export {Method};