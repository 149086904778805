import {useSoroban} from '../../../../packages/soroban/Soroban.jsx';

import {Method} from '../Method';

function GetNetwork(props) {
  const soroban = useSoroban();

  // const getNetwork = async () => {
  //   return await soroban.network.getNetwork();
  // };
  
  const request = {
    method: 'getNetwork'
  };

  return (
    <Method
      name="getNetwork"
      isWIP={true}
      description="Find information about the currently selected blockchain network."
      request={request}
    />
  );
}

export {GetNetwork};