import {createElement} from 'react';
import {css, cx} from '@emotion/css';

import {Wrapper} from './components/Wrapper.jsx';

const styles = {
  row: css({
    display: 'flex',
    flexDirection: 'row',
    margin: '0 0 16px 0',
  }),
  index: css({
    padding: '6px 8px 0 0',
  }), 
  input: css({
    flex: '1',
  }),
  controls: css({
    margin: '0 0 0 8px',
  })
};

function InputArray(props) {

  const add = () => {
    props.onChange(
      props.value ?
      [...props.value, null] :
      [null]
    );
  };
  const remove = (cursor) => {
    props.onChange(
      props.value.filter((candidate, index) => index !== cursor)
    );
  };

  const onChange = (cursor, nextValue) => {
    props.onChange(
      props.value.map((value, index) => index === cursor ? nextValue : value)
    );
  };

  const canAdd = (
    props.maxLength === undefined ||
    props.value === null ||
    props.value && (
      props.maxLength === undefined ||
      props.value.length < props.maxLength
    )
  ) && !props.isFixed;
  
  return (
    <Wrapper label={props.label}>
      {props.value && props.value.map((value, index) => {
        return (
          <div
            key={index}
            className={cx(styles.row)}
          >
            <div className={cx(styles.index)}>
              <span className="badge bg-secondary">{index}</span>
            </div>
            <div className={cx(styles.input)}>
              {createElement(
                props.input.type,
                {
                  ...props.input.props,
                  hasFloatingLabel: false,
                  value,
                  onChange: onChange.bind(null, index)
                }
              )}
            </div>
            {!props.isFixed &&
              <div className={css(styles.controls, 'ms-1')}>
                <button className="btn btn-outline-danger" onClick={remove.bind(null, index)}>
                  remove
                </button>
              </div>
            }
          </div>
        );
      })}
      {canAdd &&
        <div>
          <button className="btn btn-outline-primary" onClick={add}>
            add
          </button>
        </div>
      }
    </Wrapper>
  );
}

export {InputArray};